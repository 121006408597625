import React from "react";
import { Link, useParams } from "react-router-dom";

const LocalizedLink = ({ to, ...props }) => {
    const { locale } = useParams();
    // On s'assure d'avoir une locale sinon on peut utiliser une valeur par défaut
    const currentLocale = locale || "fr";
    return <Link to={`/${currentLocale}${to}`} {...props}/>;
};

export default LocalizedLink;
