import { Form } from "react-router-dom";
import Error from "../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import Lang from '../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowRightToBracket, faBorderStyle, faChartArea,
    faCircleCheck,
    faCircleExclamation, faCopy, faCreditCard, faDoorOpen, faFolder,
    faGear, faGlobe, faKey,
    faLock, faQrcode, faStar, faUsers, faWifi
} from "@fortawesome/free-solid-svg-icons";
import {faForward} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import  Meta  from "../component/Meta";
import PasswordStrengthMeter from "../component/PasswordStrengthMeter";
import zxcvbn from 'zxcvbn';
import CookieConsent from "react-cookie-consent";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { usePostHog } from 'posthog-js/react'
import GoogleButton from "./GoogleButton";
import FacebookButton from "./FacebookButton";
export default function Register(props) {

    const [inputs, setInputs] =  useState({email : props.email, password : '', confirmPassword : '', langageId: ''});
    const { t } = useTranslation();
    const [isCreate, setIsCreate] = useState(false);

    const posthog = usePostHog()
    const [langages, setLangage] = useState([]);

    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        posthog.capture(
            'register',
            {
                $set: { referer: document.referrer }
            }
        )



    }, []);

    const meta = {
        title: t('Créer un compte gratuitement '),
        description: t('i am a description, and i can create multiple tags'),
        canonical: global.config.baseUri+'/register',
        image : '',
        keywords: t('react,meta,document,html,tags'),
        author: global.config.author,
    }

    const [error, setError] = useState({typeError : '', messageError: '', iconFont : ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faForward} />});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const onSubmit = (data) => {
        const email = data.email;
        const password = data.password;
        const confirmPassword = data.confirmPassword;
        const langId = data.langageId;

        const langStr = langages[langId-1].lang;

        if (password != confirmPassword) {
            setError(previousState => {
                return { ...previousState, typeError: "danger" }
            });
            setError(previousState => {
                return { ...previousState, messageError: t('Mot de passe non identique')}
            });
        } else {

            const score = zxcvbn(password).score;

            if (score < 3) {
                setError(previousState => {
                    return { ...previousState, typeError: "danger" }
                });
                setError(previousState => {
                    return { ...previousState, messageError: t('Mot de passe trop faible')}
                });
                return;
            } else {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({"email": email,"password": password, "langId": langId, 'lang': langStr});

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                setButton(previousState => {
                    return { ...previousState, disabled: 'disabled' }
                });
                setIconButton(previousState => {
                    return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
                });

                fetch(global.config.uriAPI+"/createuser", requestOptions)
                    .then((response) => {
                        if(response.status != 200) throw new Error(response.status);
                        else return response.json();
                    })
                    .then(result => {
                        //setError(values => ({...values, [name]: value}))
                        setError(previousState => {
                            return { ...previousState, typeError: "success" }
                        });
                        setError(previousState => {
                            return { ...previousState, messageError: t('Merci pour la création de votre compte, vous pouvez maintenant vous connecter en cliquant sur le lien ci dessous') }
                        });
                        setIsCreate(true);

                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append('Authorization', 'bearer '+global.config.strapi);

                        var rawStrapi = JSON.stringify({
                            "data" : {
                                "email" : email, "lang": langStr
                            }
                        });

                        posthog.capture(
                            'signup',
                            {
                                $set: { referer: document.referrer },
                                email: email
                            }
                        )

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body:  rawStrapi,
                            redirect: 'follow'
                        };

                        fetch(global.config.uriStrapi+'/userokrs', requestOptions)
                            .then((response) => {
                                if(response.status != 200) throw new Error(response.status);
                                else return response.json();
                            })
                            .then((data) => {
                            });
                    })
                    .catch( (error) => {
                        setError(previousState => {
                            return { ...previousState, typeError: "warning" }
                        });
                        setError(previousState => {
                            return { ...previousState, messageError: t('Une erreur est survenue')}
                        });

                        setError(previousState => {
                            return { ...previousState, iconFont:  <FontAwesomeIcon icon={faCircleExclamation} />}
                        });
                        setButton(previousState => {
                            return { ...previousState, disabled: '' }
                        });
                        setIconButton(previousState => {
                            return { ...previousState, icon:  <FontAwesomeIcon icon={faArrowRightToBracket} />}
                        });
                    } );
            }
        }
    }


    return (
        <section className="bg-cyan-300 min-h-screen flex items-center justify-center  ">
            <Meta meta={meta} />
            <div className="bg-white shadow-lg rounded-2xl p-8 max-w-4xl w-full flex flex-col md:flex-row items-center justify-center">
                <div className="md:w-1/2 flex items-center justify-center flex-col gap-2">
                    <img src={Logo}  className={"w-1/4"}/>
                    <Error typeError={error.typeError} message={error.messageError} iconFont={error.iconFont} />
                    <div className={"mt-5"}>
                        <GoogleButton />
                    </div>

                    <div class="flex items-center gap-2 mt-5 mb-5">
                       <span class="border-t border-gray-400 w-full">
                       </span>
                        <span class="text-sm text-gray-300 uppercase oswald-font">
                            {t('ou')}
                       </span>
                        <span class="border-t border-gray-400 w-full">
                       </span>
                    </div>

                    {isCreate ?
                        (<a href={'/login'} className="text-cyan-600 py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold p-2"><FontAwesomeIcon icon={faForward} /> {t('Connexion')}</a>)
                        :
                        (<Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate">
                            <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">
                                {t('Créer un compte gratuitement')}
                            </h3>
                            <div className="mt-3">
                                <label htmlFor="email" className={ errors.email ?  " block text-sm font-medium text-gray-700" : "block text-sm mb-2"}>{t('Adresse Email')}</label>
                                <input type="text" id="email"
                                       name ="email"
                                       aria-invalid={errors.email ? "true" : "false"}
                                       defaultValue={inputs.email|| ""}
                                       {...register("email", { onChange: handleChange, required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                                       className= { errors.email ?
                                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                       }
                                       placeholder={t("Email")}/>
                                {errors.email && errors.email.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                                {errors.email && errors.email.type === "pattern" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                                )}
                            </div>
                            <div className="mt-3">
                                <label htmlFor="password" className={ errors.password ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>
                                    {t('Mot de passe')}
                                </label>
                                <input type="password" id="password"
                                       name="password"
                                       defaultValue={inputs.password|| ""}
                                       onChange={handleChange}
                                       aria-invalid={errors.password ? "true" : "false"}
                                       {...register("password", { onChange: handleChange, required: true })}
                                       className= { errors.password ?
                                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                       }
                                       placeholder={t('password')}/>
                                {errors.password && errors.password.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                                <PasswordStrengthMeter  password={inputs.password}/>
                                <p class="text-xs bg-gray-200 p-2 rounded m-1">
                                    <FontAwesomeIcon icon={faLock} /> <b>{ t('Le mot de passe doit contenir')} :</b>
                                        <br /> - { t('6 caractères minimun')}
                                        <br /> - { t('Des majuscules A-Z')}
                                        <br /> - { t('Des chiffres  0-9')}
                                        <br /> - { t('Des caratères spéciaux $^!')}
                                </p>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="password" className={ errors.confirmPassword ?  "block text-sm mb-2 text-red-700" : "block text-sm mb-2"}>
                                    {t('Confirmez votre Mot de passe')}
                                </label>
                                <input type="password" id="confirmPassword"
                                       defaultValue={inputs.confirmPassword|| ""}
                                       aria-invalid={errors.confirmPassword ? "true" : "false"}
                                       {...register("confirmPassword", { onChange: handleChange, required: true })}
                                       className= { errors.confirmPassword ?
                                           "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                           "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                                       }
                                       placeholder={t('password')}/>
                                {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}
                            </div>
                            <div className="mt-3">
                                {langages.length >0 ? (
                                    <select className={errors.langageId ? "text-base mb-2 rounded px-2 py-1 w-full focus:border-red-600 text-red-700 border border-red-700":
                                        "text-base mb-2 rounded px-2 py-1 w-full"
                                    } id="langageId"
                                            onChange={handleChange}
                                            aria-invalid={errors.langageId ? "true" : "false"}
                                            {...register("langageId", { required: true })}
                                    >
                                        <option value="">{t('Choisir une langue')}</option>
                                        {langages.map((langage) =>

                                            <option value={langage.id}><span className="uppercase">{langage.lang}</span>
                                            </option>,
                                        )}
                                    </select>
                                ) : (
                                    <FontAwesomeIcon icon={faSpinner} />)}
                                {errors.langageId && errors.langageId.type === "required" && (
                                    <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                )}

                            </div>
                            <div className="mt-5">
                                <button type="submit" disabled={button.disabled}
                                        className="bg-cyan-500 text-white p-2 w-full rounded-lg hover:bg-cyan-700 hover:text-white font-medium text-lg shadow">
                                    {iconButton.icon}&nbsp;&nbsp;{t('Créer mon compte')}
                                </button>

                            </div>


                            <div className="mt-5">
                                <a href="/login" className="ext-black py-1 w-full text-xs
                                 font-semibold">{t('Revenir à l\'écran de connexion')}</a>
                            </div>
                                <div>
                                    <a href="/" className="text-black py-1 w-full text-xs
                                 font-semibold">{t('Accueil')}</a>
                                </div>
                        </Form>
                        )
                    }
                    <Lang />
                </div>
                <div className="flex flex-col items-center justify-center gap-5 md:w-1/2 ">
                    <h3 className={"text-3xl text-cyan-700 font-bold "}><FontAwesomeIcon icon={faStar} /> {t('Essai gratuit 5 jours')} <FontAwesomeIcon icon={faStar} /></h3>
                    <p class="flex items-center text-sm text-gray-700  gap-2 "><FontAwesomeIcon icon={faCreditCard} /> <span>{t('Sans carte bancaire requise')}</span></p>
                    <div className={"mt-4 bg-green-100 p-6 rounded-lg shadow-md text-center"}>
                        <ul role="list" class=" text-sm text-gray-700 space-y-2 text-left">
                            <li className="flex items-center space-x-3 text-sm text-gray-700">

                                <FontAwesomeIcon icon={faGear} className={"text-green-600"} />
                                <span>{t('Génération automatique')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">
                                <FontAwesomeIcon icon={faDoorOpen} className={"text-green-600"} />
                                <span >{t('Régles d\entrée et de sortie')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">
                                <FontAwesomeIcon icon={faWifi} className={"text-green-600"} />
                                <span>{t('Wifi et portail Captif')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">
                                <FontAwesomeIcon icon={faKey} className={"text-green-600"} />
                                <span>{t('Code de la boîte à clef')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">



                                <FontAwesomeIcon icon={faQrcode}className={"text-green-600"} />
                                <span >{t('Génération affiche A4 QRCode')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faFolder} className={"text-green-600"} />
                                <span>{t('Catégories en illimitées')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faFolder} className={"text-green-600"} />
                                <span>{t('Contenu illimités')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faChartArea} className={"text-green-600"} />
                                <span>{t('Statistiques')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faQrcode} className={"text-green-600"} />
                                <span>{t('QrCode pour les notices techniques')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faCopy} className={"text-green-600"} />
                                <span >{t('Dupliquer un livret')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faBorderStyle} className={"text-green-600"} />
                                <span>{t('Livret avec theme couleur')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">


                                <FontAwesomeIcon icon={faGlobe} className={"text-green-600"} />
                                <span >{t('Traduction en plusieurs langues')}</span>
                            </li>
                            <li className="flex items-center space-x-3 text-sm text-gray-700">

                                <FontAwesomeIcon icon={faUsers} className={"text-green-600"}/>
                                <span>{t('Voyageurs illimités')}</span>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </section>
    )
}