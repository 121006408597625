import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import PlaceholderImg from '../../asset/img/placeholder.png';
import LocationImg from '../../asset/img/location.png';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowAltCircleDown, faArrowLeft, faBook, faCaretDown, faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch
} from "@fortawesome/free-solid-svg-icons";

import Meta from "../../component/Meta";
import LangFront from "./langFront";
import {Avatar, Button, Modal} from 'flowbite-react';
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import GoogleMapReact from 'google-map-react';


export default function TopicDetail(props) {
    let { topicUuid, uuid } = useParams();
    const [topic, setTopic] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const { t } = useTranslation();
    const [guideBook, setGuideBook] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [openModal, setOpenModal] = useState(false);
    const [hoveredItems, setHoveredItems] = useState([]);
    const [topicShow, setTopicShow] = useState([]);


    useEffect(() => {

        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid+"/"+langId, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);

                fetch(global.config.uriAPI+'/guidebook/topicshow/'+topicUuid+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setTopic(data);
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }, []);

    const handleHover = (index) => {
        return () => {
            setHoveredItems(previousState => {
                return {...previousState, [index]: !previousState[index]}
            })
        }
    };


    const Placeholder = ({ text }) => <img src={PlaceholderImg} style={{ width:32, height: 'auto'}} />;
    const Location = ({ text }) => <img src={LocationImg} style={{ width:32, height: 'auto'}} />;


    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
            {loadData ?                <div  className={"container  max-w-screen-md mx-auto  flex flex-col gap-5"} >
                    <Meta meta={{
                        title: guideBook.name,
                        font: guideBook.settings.mainFont,
                        description: guideBook.name,
                        image: global.config.uriAPI+'/'+guideBook.media.url,
                        themeColor: guideBook.settings.secondaryColor
                    }} />
                    <header className={"flex flex-col justify-between  w-full min-h-[250px] rounded-b-2xl shadow-lg"}
                            style={{
                                backgroundImage:  `linear-gradient(
                             rgba(255, 255, 255, 0),
                            rgba(255, 255, 255, 0)
                        ), url(${global.config.uriAPI+'/'+topic.media.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                    >
                        <div className={"flex flex-row justify-between items-start"}>
                            <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} className={"m-5"}/>
                            <LangFront langage={langage} settings={guideBook.settings} />
                        </div>

                    </header>

                    <section className={"p-5"}>
                        <div className={"flex flex-row gap-5 items-center"}>
                            {topic?.sub_category_id > 0 ?
                                <>
                                    <div className={"rounded p-1 text-xs"}
                                         style={{
                                             backgroundColor:guideBook.settings.primaryColor,
                                             color: guideBook.settings.secondaryColor
                                         }}
                                    >
                                        <i className={topic.subCategory.icon} />
                                    </div>

                                </>
                                : <></>}
                            <h1 className={"lowercase first-letter:uppercase text-gray-700 font-semibold"}>{topic?.name}</h1>
                        </div>
                        <div className={"text-gray-500 text-sm first-letter:uppercase"}>{topic?.subCategory?.name}</div>

                        {topic?.content !== "" ?
                            <p className="text-sm  text-gray-600  rounded  p-2 mt-5" dangerouslySetInnerHTML={{__html: topic?.content}}></p>
                            :
                            <></>}

                        {topic?.featureElectricals?.length > 0 ?
                            <div className={"mt-5"}>
                                <div className={"text-gray-700 font-bold text-sm flex items-center justify-center gap-2 mb-5"}><FontAwesomeIcon icon={faBook} /> {t("Guide d'utilisation")}</div>
                                {topic?.featureElectricals.map((feature, index) =>
                                    <div key={index} className={"flex flex-col gap-2 mt-2 rounded border border-gray-200 shadow p-2"}>
                                        <div className={"text-gray-700 font-bold text-sm flex justify-between"}>
                                            <span className={" first-letter:uppercase"}>{feature.name}</span>
                                            <span className={"hover:cursor-pointer"}
                                                  onClick={(event) => {
                                                      document.getElementById("featuredetails_"+index).classList.toggle("hidden");
                                                  }}
                                            ><FontAwesomeIcon icon={faCaretDown} /></span>
                                        </div>
                                        <div className={"hidden text-gray-600 text-normal"} id={"featuredetails_"+index} >

                                            <div className={"flex flex-row flex-nowrap gap-2"}>
                                                {feature?.media?.map((media) =>
                                                    <div>
                                                        <img src={global.config.uriAPI+"/"+media.url} className={"w-full rounded"} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"mt-5"}>{feature.description}</div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            : null}

                        <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
                    </section>
                </div>
                :
                <img src={LogoAnime}
                     style={{
                         width: 80,
                         $height: 80
                     }}
                     className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>
            }

        </div>
    )
}