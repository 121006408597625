import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import i18n from "./i18n";
import FrFlag from '../asset/img/fr.jpg';
import EnFlag from '../asset/img/en.jpg';

function Lang(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { locale } = useParams();

    // Fonction pour extraire la partie de base d'un code langue (ex: "en" depuis "en_Bg")
    const getBaseLang = (langCode) => langCode.split('-')[0];

    const currentLang = i18n.language;
    const baseCurrentLang = getBaseLang(currentLang);
    const baseUrlLocale = locale ? getBaseLang(locale) : baseCurrentLang;

    function onChangeLang(langStr, langId) {
        const routeLang = getBaseLang(langStr);
        i18n.changeLanguage(langStr);
        localStorage.setItem("langId", langId);
        localStorage.setItem("langStr", langStr);
        const segments = location.pathname.split("/");
        segments[1] = routeLang;
        const newPath = segments.join("/") || `/${routeLang}`;
        navigate(newPath);
    }

    const [langage, setLangage] = useState([]);
    useEffect(() => {
        fetch(global.config.uriAPI + "/langage")
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
                // gestion d'erreur si nécessaire
            });
    }, []);

    return (
        <div className="flex gap-2 justify-center items-center">
            {langage.map((lang) => {
                const baseLang = getBaseLang(lang.lang);
                const isActive = baseUrlLocale === baseLang;
                return (
                    <a
                        key={lang.id}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            onChangeLang(lang.lang, lang.id);
                        }}
                        className={
                            isActive
                                ? "border-2 border-gray-300 rounded-full"
                                : "border-2 border-transparent rounded-full hover:border-gray-300"
                        }
                    >
                        <img
                            src={global.config.uriAPI + "/" + lang.flag}
                            alt={lang.lang}
                            className="w-4 h-4 rounded-full shadow-lg m-1"
                        />
                    </a>
                );
            })}
        </div>
    );
}

export default Lang;
