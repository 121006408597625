import Logo from '../asset/img/okurensiologo.svg';
import Loginimg from '../asset/img/loginimg.png';
import {Form, useParams} from "react-router-dom";
import Error from "../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Card, Navbar} from 'flowbite-react';

import  Meta  from "../component/Meta";


import Img1 from '../asset/img/architecture-2260836_640.jpg';
import Img2 from '../asset/img/hot-air-balloons-4561267_640.jpg';
import Img3 from '../asset/img/language-2345801_640.jpg';
import Img4 from '../asset/img/qrcode.jpg';
import Demo1 from '../asset/img/demo1.png';
import Demo2 from '../asset/img/demo2.png';
import i18n from "../component/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCircleArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import TextTruncate from 'react-text-truncate';
import { Pagination } from 'flowbite-react';
import Lang from '../component/lang.jsx'
import CookieConsent from "react-cookie-consent";
import DetectLang from "./detectLang";
import LocalizedLink from "./LocalizedLink";
export default function Article() {

    const { t } = useTranslation();

    const font = {
        fontFamily: 'Inter',
    }

    let { id, slug } = useParams();


    const lng = i18n.language;
    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState(false);
    const [metaArticle, setMetaArticle] = useState({title: '', description: '', image: ''});

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+global.config.strapi)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriStrapi+'/articles/'+id+'/?locale='+lng+'&populate=*', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setArticle(data.data);
                setLoading(true);
                setMetaArticle({
                    title: data.data.attributes.title,
                    description: data.data.attributes.descriptionCourte,
                    image: global.config.urlStrapi+data.data.attributes.image.data[0].attributes.formats.small.url
                }) ;
            })
            .catch((err) => {
            });


    }, [lng]);


    const desc = {
        h2: {
            fontSize: '1.5rem',
            margin: 5
        }
    }

    const openMobileMenu = () => {
        const mobileMenu = document.getElementById('mobile-menu-2');
        if (mobileMenu.style.display === 'block') {
            mobileMenu.style.display = 'none';
        } else {
            mobileMenu.style.display = 'block';
        }
    }

    return (
        <>
            <DetectLang />
            <header>
                <nav class="bg-white border-gray-200 dark:bg-gray-900 fixed w-full shadow">
                    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
                        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={Logo} className="h-8" alt="okurensio Logo"/>
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-gray-700">Okurensio</span>
                        </a>
                        <button data-collapse-toggle="mobile-menu-2" type="button" onClick={openMobileMenu}
                                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                aria-controls="navbar-default" aria-expanded="false">
                            <span class="sr-only">Open main menu</span>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div class="hidden w-full md:block md:w-auto" id="mobile-menu-2">
                            <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <LocalizedLink to="/"
                                                   className="block py-2 px-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0 dark:text-white md:dark:text-cyan-500"
                                                   aria-current="page">{t('Accueil')}</LocalizedLink>
                                </li>
                                <li>
                                    <LocalizedLink to="/tarif"
                                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                                        {t('Tarif')}
                                    </LocalizedLink>
                                </li>
                                <li>
                                    <LocalizedLink to="/login"
                                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Mon compte')}</LocalizedLink>
                                </li>
                                <li>
                                    <LocalizedLink to="/register"
                                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Inscription')}</LocalizedLink>
                                </li>
                                <li>
                                    <LocalizedLink to="/articles"
                                                   className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-700 md:p-0 dark:text-white md:dark:hover:text-cyan-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">{t('Guides')}</LocalizedLink>
                                </li>
                                <li>
                                    <Lang/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <section class="bg-white dark:bg-gray-900  min-h-screen  justify-center items-center flex flex-col gap-5">
                <div className="flex rounded-2xl  p-5 items-center flex-col container mt-10 mb-10 ">
                    <nav className={"flex m-5 justify-start"} aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li className="inline-flex items-center">
                                <a href="/"
                                   className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-cyan-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                         fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                                    </svg>
                                    {t('Accueil')}
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2" d="m1 9 4-4-4-4"/>
                                    </svg>
                                    <a href="/articles"
                                       className="ms-1 text-sm font-medium text-gray-700 hover:text-cyan-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{t('Guides')}</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2" d="m1 9 4-4-4-4"/>
                                    </svg>
                                    <span
                                        class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{article?.attributes?.title}</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    {loading ?
                        <div>
                            <div style={{
                                backgroundImage: `url(${global.config.urlStrapi+article.attributes.image.data[0].attributes.formats.small.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                height: 320,
                                display: 'block',
                            }}  className={"rounded"}
                            ></div>
                            <h1 className={"font-bold text-3xl mt-10"}>{article.attributes.title}</h1>
                            <p  style={desc} className={"mt-10 contentArticle"}  dangerouslySetInnerHTML={{__html: article.attributes.descriptionck}}></p>
                        </div>
                        : <FontAwesomeIcon icon={faSpinner} spin={true} />}
            <a href={"/cgv"} className={"hover:bg-cyan-700 text-cyan-700 hover:rounded hover:text-white p-2 text-xs"}>{t('Mentions légales')}</a>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t('Accepter')}
                cookieName="okurensio"
                style={{ background: "#2B373B" }}
                buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
                expires={150}
            >
                {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
            </section>
        </>
    );
}