import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faHome,
    faLocationDot,
    faSearch,
    faPercent, faArrows, faCalendar, faCalendarTimes, faCalendarDays, faTemperatureQuarter, faWind, faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import LogoAnime from "../../asset/img/logoanime.gif";
import NBar from "./nbar";
import mixpanel from "mixpanel-browser/src/loader-module";
import {faTag} from "@fortawesome/free-solid-svg-icons/faTag";
import {faEuro} from "@fortawesome/free-solid-svg-icons/faEuro";
import {CSS} from "@dnd-kit/utilities";
import DeleteByPath from "../admin/guideBook/function/DeleteByPath";
import {Tooltip} from "flowbite-react";
import Event from "./event";

import { usePostHog } from 'posthog-js/react'
function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function hexToRGB(hex, alpha) {

    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export default function GuideBookIndex(props) {

    let { uuid } = useParams();
    const queryParameters = new URLSearchParams(window.location.search)
    const origin = queryParameters.get("origin");

    const [guideBook, setGuideBook] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [meta, setMeta] = useState({title: '', font: '', description: '', image: ''});
    const [styles, setStyles] = useState({overlay: '', item: '', itemActive: '', navigationBar: ''});
    const [hoveredItems, setHoveredItems] = useState([]);
    const [langage, setLangage] = useState([]);
    const langId = localStorage.getItem('langId');
    const posthog = usePostHog()

    if (langId === null) {
        localStorage.setItem('lngSelect', "fr");
        localStorage.setItem('langId', 1);
        const langId = localStorage.getItem('langId');
    }

    const [categorys, setCategorys] = useState([]);
    const [weather, setWeather] = useState(null);
    const handleHover = (index) => {
           return () => {
                setHoveredItems(previousState => {
                    return {...previousState, [index]: !previousState[index]}
                })
            }
    };

    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        if (langId === null) {
           const langId = 1;
        }

        posthog.capture(
            'guidebook',
            {
                $set: { referer: document.referrer },
                uuid: uuid
            }
        )

        fetch(global.config.uriAPI+'/guidebook/'+uuid+"/"+langId+"?origin="+origin, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);


                /*if (data.media.length === 0) {

                } else {*/
                   /* const updateStyles = {
                        overlay : {
                            position: 'relative',
                        },
                        item: {
                            backgroundColor: data.settings.navigationBarColor,
                        },
                        itemHover:{
                            backgroundColor: adjust(data.settings.navigationBarColor, -40)
                        },
                        itemActive: {
                            backgroundColor: adjust(data.settings.navigationBarColor, -40)
                        },
                        navigationBar: {
                            color: '#fff',
                            fontFamily: data.settings.mainFont,
                            fontSize: '0.7rem',
                            height: '70px'
                        }
                    }

                    setStyles(updateStyles);*/
                fetch(global.config.uriAPI+'/guidebook/category/'+uuid+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setCategorys(data);
                        setLoadData(true);
                    })
                    .catch((err) => {
                    });


                fetch(global.config.uriAPI+'/guidebook/weather/'+data.longitude+"/"+data.latitude+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setWeather(data);
                    })
                    .catch((err) => {
                    });

            })
            .catch((err) => {
            });
    }, [langId]);
    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(null);



    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
        {loadData   ?
            (
                <>
                <div  className={"container  max-w-screen-md mx-auto p-5 flex flex-col gap-5"} >
                    <Meta meta={{
                        title: guideBook.name,
                        font: guideBook.settings.mainFont,
                        description: guideBook.name,
                        image: global.config.uriAPI+'/'+guideBook.media.url,
                        themeColor: guideBook.settings.secondaryColor
                    }} />
                    <header className={"flex items-center justify-between  w-full"}>
                        <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} style={{
                            backgroundColor:"#F5F5F5"
                        }}/>
                        <LangFront langage={langage} settings={guideBook.settings}  />
                    </header>
                    <div className={" p-2 flex flex-col gap-2 bg-white rounded shadow border border-gray-200"}>
                        <h1 className={"text-xl font-bold first-letter:uppercase text-gray-700 oswald-font"}>{guideBook.name}</h1>
                        <p dangerouslySetInnerHTML={{__html: guideBook.ws.content}} className={"text-sm text-gray-600 lato-regular"}></p>
                    </div>

                    <div className={" p-2 flex flex-col gap-2 bg-white rounded shadow border border-gray-200 overflow-auto max-h-[300px]"}>
                        <div className={"flex flex-row justify-between items-center"}>
                            <h2 className={"text-sm font-bold first-letter:uppercase text-gray-700 oswald-font"}>{t('Evènements')}</h2>
                            <Tooltip content={t('Evènements à venir')}>
                                <a href={"/guidebook/events/"+guideBook.uuid}
                                   className={"rounded p-2 text-xs"}
                                   style={{
                                       backgroundColor: guideBook.settings.primaryColor,
                                       color: guideBook.settings.secondaryColor
                                   }}
                                ><FontAwesomeIcon icon={faCalendarDays} /></a>
                            </Tooltip>

                        </div>
                        <Event  calendars={guideBook.calendars} guideBook={guideBook} border={null}/>
                    </div>

                    <div className={"grid lg:grid-cols-2 gap-5 items-stretch mb-14"}>
                        {categorys.map((category, index) =>
                            category.module_name === 'logement' ?
                                <div   className={"shadow rounded border border-gray-200 w-full bg-white p-5  h-full flex  min-h-[300px]  bottom-5 justify-end flex-col "}
                                       style={{
                                           backgroundImage: `url(${global.config.uriAPI+'/'+guideBook.media.url})`,
                                           backgroundSize: 'cover',
                                           backgroundPosition: 'center'
                                       }}
                                >
                                    <a href={"/guidebook/subcategory/"+category.uuid+"/"+guideBook.uuid} className={"rounded p-2  text-white font-bold flex justify-center items-center text-lg first-letter:uppercase cursor-pointer"}
                                       onMouseEnter={() => setHoverIndex(category.id)} // Définit l'index survolé
                                       onMouseLeave={() => setHoverIndex(null)}
                                       style={{ backgroundColor:  hoverIndex === category.id ? guideBook.settings.primaryColor: guideBook.settings.secondaryColor,
                                           borderColor: guideBook.settings.primaryColor,
                                       }}
                                    ><span className={"first-letter:uppercase oswald-font"}>{category.name}</span></a>
                                </div>
                                :
                            <div   className={"shadow rounded border border-gray-200 w-full bg-white p-5  h-full flex  min-h-[300px]  bottom-5 justify-end flex-col "}
                                  style={{
                                      backgroundImage: `url(${global.config.uriAPI+'/'+category.media.url})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center'
                                  }}
                            >
                                <a href={"/guidebook/subcategory/"+category.uuid+"/"+guideBook.uuid} className={"rounded p-2 text-white font-bold flex justify-center items-center text-lg first-letter:uppercase cursor-pointer"}
                                   onMouseEnter={() => setHoverIndex(category.id)} // Définit l'index survolé
                                   onMouseLeave={() => setHoverIndex(null)}
                                   style={{ backgroundColor:  hoverIndex === category.id ? guideBook.settings.primaryColor: guideBook.settings.secondaryColor }}
                                ><span className={"first-letter:uppercase"}>{category.name}</span></a>
                            </div>
                        )}
                        {weather != null ?
                            <div className="w-full mx-auto p-4 bg-gradient-to-b  text-white rounded shadow border border-gray-200 min-h-[300px]"
                                 style={{
                                     background: `linear-gradient(to bottom, ${guideBook.settings.secondaryColor}, ${guideBook.settings.primaryColor})`,
                                 }}
                            >
                                <h3 className="text-lg font-bold text-center">{t('Météo')}</h3>
                                <div className="flex justify-center items-center mt-2 text-sm">
                                    <p className="text-lg">{new Date().toLocaleDateString("fr-FR", { day: 'numeric', month: 'long' })}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 mt-2">
                                    <div className="flex flex-col items-center  p-2  bg-white rounded-xl"
                                         style ={{
                                             color: guideBook.settings.secondaryColor
                                         }}
                                    >
                                        <img
                                            src={`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`}
                                            alt={weather.current.weather[0].description}
                                            className="w-12 h-12"
                                        />
                                        <p className="text-sm font-bold ">{weather.current.weather[0].description}</p>
                                    </div>
                                    <div className="flex flex-col items-center  bg-white  p-2 rounded-xl"
                                         style ={{
                                             color: guideBook.settings.secondaryColor
                                         }}
                                    >
                                        <p className="text-4xl font-bold ">{Math.round(weather.current.temp)}°</p>
                                        <p  className="text-sm font-bold "><FontAwesomeIcon icon={faWind} /> {weather.current.wind_speed} km/h</p>
                                    </div>
                                </div>
                                <div className="max-w-[300px] mx-auto"
                                    style = {{
                                        maxWidth: "300px !important"
                                    }}
                                >
                                    <div
                                        className="flex flex-row gap-3  flex-nowrap mt-2  bg-white rounded p-4 scrollbar-hide w-full overflow-x-auto"
                                        style={{
                                            WebkitOverflowScrolling: "touch",
                                            scrollbarWidth: "none",
                                            msOverflowStyle: "none",
                                            WebkitScrollbar: "none",
                                            "&::-webkit-scrollbar": {
                                                display: "none",
                                                width: 0,
                                                height: 0,
                                                background: "transparent"
                                            }
                                            }}
                                    >
                                        {weather.hourly.map((hour, index) =>
                                            <div key={index} className={"flex  gap-2 flex-col text-gray-700 justify-center items-center flex-shrink-0 w-[40px]"}  >
                                                <div className={" text-gray-500"} style={{
                                                    fontSize: "11px"
                                                }}>
                                                    {hour.temp}°
                                                </div>
                                                <div>
                                                    <img
                                                        src={`http://openweathermap.org/img/wn/${hour.weather[0].icon}@2x.png`}
                                                        alt={hour.weather[0].description}
                                                        className="w-8 h-8"
                                                    />
                                                </div>
                                                <div className={" text-gray-500"} style={{
                                                    fontSize: "11px"
                                                }}>
                                                    {new Date(hour.dt * 1000).toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mt-6 text-right">
                                    <a href={`/guidebook/meteo/${guideBook.uuid}`} className={"rounded bg-white p-2"}
                                       style = {{
                                           color: guideBook.settings.secondaryColor
                                       }}
                                    ><FontAwesomeIcon icon={faArrowRight} /></a>
                                </div>
                            </div>

                            : null }


                    </div>

                </div>
                    <div className={"text-gray-600  container max-w-screen-md mx-auto p-5  justify-center items-center flex text-xs mb-20 gap-5"}>
                       <span> @{t('Copyright by Okurensio')}</span> <a href={"https://okurens.io"} target={"_blank"}>https://okurens.io</a>
                    </div>
                    <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
                </>
            )
                : <img src={LogoAnime}
                       style={{
                           width: 80,
                           $height: 80
                       }}
                       className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>}</div>

    )
}