import { Form, useParams } from "react-router-dom";
import Error from "../../component/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCalendarDays, faChevronLeft, faLocationDot, faWind} from "@fortawesome/free-solid-svg-icons";
import ModalEvent from "./modalEvent";
import Meta from "../../component/Meta";
import LangFront from "./langFront";
import NBar from "./nbar";
import LogoAnime from "../../asset/img/logoanime.gif";
import imgMeteo from "../../asset/img/meteo.jpg";
export default function Meteo(props) {
    const { t } = useTranslation();

    const langId = localStorage.getItem('langId');
    let { uuid } = useParams();

    const calendars = props.calendars;

    const [eventSelected , setEventSelected] = useState(null);
    const [guideBook, setGuideBook] = useState([]);
    const [weather, setWeather] = useState(null);
    const [langage, setLangage] = useState([]);

    useEffect(() => {
        fetch(global.config.uriAPI+'/langage')
            .then((response) => response.json())
            .then((data) => {
                setLangage(data);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/guidebook/'+uuid+"/"+langId, )
            .then((response) => response.json())
            .then((data) => {
                setGuideBook(data);


                fetch(global.config.uriAPI+'/guidebook/weather/'+data.longitude+"/"+data.latitude+"/"+langId, )
                    .then((response) => response.json())
                    .then((data) => {
                        setWeather(data);
                    })
                    .catch((err) => {
                    });

            })
            .catch((err) => {
            });
    }, [langId]);


    return (
        <div className={"min-h-screen"} style={{
            backgroundColor: "#F5F5F5",
        }}>
            {weather !== null ?
            <div  className={"container  max-w-screen-md mx-auto  flex flex-col gap-5"} >
                <Meta meta={{
                    title: guideBook.name,
                    font: guideBook.settings.mainFont,
                    description: guideBook.name,
                    image: global.config.uriAPI+'/'+guideBook.media.url,
                    themeColor: guideBook.settings.secondaryColor
                }} />
                <header className={"flex flex-col justify-between  w-full min-h-[250px] rounded-b-2xl shadow-lg"}
                        style={{
                            backgroundImage:`linear-gradient(
                             rgba(255, 255, 255, 0),
                            rgba(255, 255, 255, 0)
                        ), url(${imgMeteo})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                >
                    <div className={"flex flex-row justify-between items-start"}>
                        <img src={"/themelogo/"+guideBook.settings.code+".svg"}  width={"10%"} className={"m-5"}/>
                        <LangFront langage={langage} settings={guideBook.settings} />
                    </div>

                </header>
                <div className={" flex justify-between items-center pr-5 pl-5"}>
                    <div className={"flex flex-row gap-2"}>
                        <a href={`/guidebook/${uuid}`} className={"rounded text-sm p-1 w-5 flex justify-center items-center"}
                           style = {{
                               backgroundColor: guideBook.settings.secondaryColor,
                               color: guideBook.settings.primaryColor
                           }}
                        ><FontAwesomeIcon icon={faChevronLeft} /></a>
                        <h1 class={"text-xl text-gray-700 font-bold first-letter:uppercase oswald-font"}>{t('Météo')}</h1>
                    </div>
                </div>
                <div className={"container max-w-screen-md mx-auto p-5 md:p-0 lg:p-0 mt-5"}>
                    <div className={"flex flex-col gap-2 items-stretch"}>
                    {weather.daily.map((day, index) =>
                        <div className=" shadow rounded border border-gray-200 w-full bg-white p-2 flex flex-row gap-5 items-stretch" >
                            <div className={" w-32 rounded "} style={{
                                backgroundImage: `url(https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png)`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}>
                            </div>
                            <div className={"flex flex-row justify-between w-full"}>
                                <div className={"flex flex-col gap-2 w-full"}>
                                    <div className={"text-gray-700 text-lg font-semibold"}>{new Date(day.dt*1000).toLocaleDateString("fr-FR", { day: 'numeric', month: 'long' })}</div>
                                    <div className={"text-gray-600  flex flex-row flex-nowrap gap-2 items-center"}>
                                        <span className={"first-letter: uppercase"}>{day.weather[0].description}</span>
                                        <div className={"bg-gray-100 p-1 rounded flex flex-row flex-nowrap gap-1"}>
                                            <div><span className={"font-semibold text-sm"}>{t('Min')} </span>{Math.round(day.temp.min)}° | </div>
                                            <div><span className={"font-semibold test-sm"}>{t('Max')}</span> {Math.round(day.temp.max)}° | </div>
                                            <div className={"text-sm"}><FontAwesomeIcon icon={faWind} /> {day.wind_speed} km/h</div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    )}
                    </div>

                </div>
                <div className={"text-gray-600  container max-w-screen-md mx-auto p-5  justify-center items-center flex text-xs mb-20 gap-5"}>
                    <span> @{t('Copyright by Okurensio')}</span> <a href={"https://okurens.io"} target={"_blank"}>https://okurens.io</a>
                </div>
                <NBar styles={guideBook.settings} guideBook={guideBook}  active={"accueil"}/>
            </div>

                : <img src={LogoAnime}
                       style={{
                           width: 80,
                           $height: 80
                       }}
                       className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}/>}

        </div>
    )

}