module.exports = global.config = {
    author: "Okurensio",
    nameSite : "Okurensio",
    baseUri: 'https://okurens.io',
    uriAPI: 'https://app.okurens.io',
    apiKeyGoogle : 'AIzaSyBDrnAVYHS02hXIqC6tB2jGizDw4L0RcFs',
    uriStrapi: 'https://strapi.okurens.io/api',
    urlStrapi: 'https://strapi.okurens.io',
    strapi: 'c6d09519cfb57ed7ec6df787648e5ec61d30657aaa26e87e1f1419697f37938ac57a5521c1c6a99a68345b690f43517e6fb4bfdb8aa4c64b994146c3686cb9438b9922d6afd1a387f9c426878b43e6068ee32c032255513dc11685f4347116bab8bea01a57a500c3a59e2f64603fb3f4c60ce854f65a752b70c5123a07c89bc0',
    frontLangId: 0,
    amplitude: 'bab4b25728fbb47b602d0c2d346560f2',
    mixpanel: "33d58790c6dc91f3c8142943cbed3633",
    REACT_APP_PUBLIC_POSTHOG_KEY: 'phc_XgTL6ebA1SwL6OQHwFak892LSaER2yUhzAKHr1cm9uz',
    REACT_APP_PUBLIC_POSTHOG_HOST: 'https://us.i.posthog.com',
    FREEPICK_API: 'FPSX476f051bf3ad4114ac5e95e788eb5ddd',
    token_facebook_api: 'EAAUab3yR9ScBO8paovwtj5o56rNxZAzbHMLcs3TWo6hrInUZAFRrNtFiOIb3FE3XETYcwZBXfyTneIpZCaleZAmnx2Rwlbb32NAAZBg8YuoyQJZC12ZBgLx7U24FyoNtotnfvOvISAjr919J3NDUUY72tgZARTWzNS65cJqMEu7pSLi9tZBFOZCgE03TVf2CJy4j2gPaQZDZD'
};
