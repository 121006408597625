import Error from "../../../../component/Error";
import React, {useState, useEffect, useCallback} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../../asset/img/loginimg.png';
import Lang from '../../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faImage, faTrash, faClose, faPlus
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../../component/i18n";
import  Meta  from "../../../../component/Meta";
import {FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "../../../../component/PluginUploader";
import DeleteMedia from "../function/DeleteMedia";
import BgNew from "../../../../asset/img/bg-4.png";
import Tags from "./tags";
import Cropper from "react-easy-crop";
import heic2any from "heic2any";


export default function TopicEditElectrical(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, subCategoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [contentType, setContentType] = useState('content');
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);
    const [summary, setSummary] = useState(props.topic.content);
    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter un contenu')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: props.topic.title, reference: props.topic.reference});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        storage.setItem("electrical_"+name, value);
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const [tagsSelected, setTagsSelected] = useState(props.topic.tagsCode);
    const onSubmitContent = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        setLoadData(false);

        formData.append('mediaId', props.topic.media.id);
        formData.append('title', storage.getItem("electrical_name"));
        formData.append('content', storage.getItem("electrical_summary"));

        formData.append('reference', inputs.reference);
        formData.append("tags", tagsSelected)

        formData.append("features", JSON.stringify(features))

        features.forEach((feature, featureIndex) => {
            if (feature.images && feature.images.length > 0) {
                feature.images.forEach((image, imageIndex) => {
                    // On vérifie que l'image est bien un objet File

                    if (image instanceof Blob) {
                        // La clé peut être adaptée selon ce que le backend attend.
                        formData.append(`features_images_${feature.id}_${imageIndex}`, image, 'image.png');
                    }
                    else {
                        if (image !== null) {
                            formData.append(`features_images_${feature.id}_${imageIndex}_mediaId`, image.id, );
                        }

                    }

                });
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/topicelectrical/update/'+props.topic.uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = document.referrer;
                return;
            })
            .catch((err) => {
            });
    }

    const [features, setFeatures] = useState(props.topic.featuresElectrical || []);

    const [cropData, setCropData] = useState({ image: null, featureId: null, index: null });
    const [cropConfig, setCropConfig] = useState({ crop: { x: 0, y: 0 }, zoom: 1 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const storage = localStorage;
    const addFeature = () => {
        setFeatures([...features, { id: Date.now(), name: "", description: "", images: [null, null] }]);

    };

    const removeFeature = (id) => {
        setFeatures(features.filter((feature) => feature.id !== id));

    };

    const handleChangeFonction = (id, field, value) => {
        setFeatures(features.map((feature) => (feature.id === id ? { ...feature, [field]: value } : feature)));

    };

    const handleImageUpload = async (id, index, event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Vérifier la taille du fichier (max 2 Mo)
        if (file.size > 2 * 1024 * 1024) {
            setErrorMessage("L'image ne doit pas dépasser 2 Mo.");
            return;
        }

        let imageUrl = URL.createObjectURL(file);

        // Vérifier et convertir HEIC si nécessaire
        if (file.type === "image/heic" || file.name.endsWith(".heic")) {
            try {
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                imageUrl = URL.createObjectURL(convertedBlob);
            } catch (error) {
                setErrorMessage("Erreur lors de la conversion de l'image HEIC.");
                return;
            }
        }

        setCropData({ image: imageUrl, featureId: id, index });
        setErrorMessage(""); // Réinitialiser les erreurs
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropComplete = async () => {
        if (!cropData.image || !croppedAreaPixels) return;

        const croppedImage = await getCroppedImg(cropData.image, croppedAreaPixels);
        setFeatures((prevFeatures) =>
            prevFeatures.map((feature) =>
                feature.id === cropData.featureId
                    ? { ...feature, images: feature.images.map((img, i) => (i === cropData.index ? croppedImage : img)) }
                    : feature
            )
        );



        // ✅ Fermer la popup après le recadrage
        setCropData({ image: null, featureId: null, index: null });
        setCroppedAreaPixels(null);
        setCropConfig({ crop: { x: 0, y: 0 }, zoom: 1 });
    };

    const removeImage = (id, index) => {
        setFeatures((prevFeatures) =>
            prevFeatures.map((feature) =>
                feature.id === id
                    ? { ...feature, images: feature.images.map((img, i) => (i === index ? null : img)) }
                    : feature
            )
        );


    };

    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <Form action="" method="post" onSubmit={handleSubmit(onSubmitContent)} noValidate id="formCreate" className={"flex flex-col gap-5 mt-5"}>
            <b className={"first-letter:uppercase font-bold text-gray-700"}>{t('Modifier un équipement')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>



            {/*<div className="mt-3 w-full">
                    <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-base  mb-2 text-gray-700 font-semibold first-letter:uppercase"}>{t('Référence')}</label>
                    <input type="text" id="reference"
                           name ="reference"
                           aria-invalid={errors.reference ? "true" : "false"}
                           defaultValue={inputs.reference|| ""}
                           {...register("reference", { onChange: handleChange, required: true})}
                           className= { errors.reference ?
                               "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                               "border rounded-lg w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 bg-gray-50 border-gray-100 p-2 text-sm"
                           }
                           placeholder={t("Référence")}/>
                    {errors.name && errors.name.type === "required" && (
                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                    )}
                </div>*/}

            <div className={"grid grid-cols-1 md:grid-cols-[2fr,3fr] gap-6 items-stretch"}>
                <div>
                    <div className="mt-3 w-full mb-3">
                        <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-base  mb-2 text-gray-700 font-semibold first-letter:uppercase"}>{t('Nom de votre équipement')}</label>
                        <input type="text" id="name"
                               name ="name"
                               aria-invalid={errors.name ? "true" : "false"}
                               defaultValue={ props.topic.title|| ""}
                               {...register("name", { onChange: handleChange, required: true})}
                               className= { errors.name ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border rounded-lg w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 bg-gray-50 border-gray-100 p-2 text-sm"
                               }
                               placeholder={t(" Ex  Micro Onde")}/>
                        {errors.name && errors.name.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>
                    {props.topic.media.url !== undefined ?
                        <div className={"relative"}>
                            <img src={global.config.uriAPI+"/"+props.topic.media.url} className={"w-full h-full object-cover rounded"} />
                            <div className={"absolute top-5 right-5"}>
                                <DeleteMedia  setSetter={props.setTopic}  setter={props.topic} uuid={props.topic.media.uuid} />
                            </div>
                        </div>
                        :
                        <div id="fileUpload" className="w-full">
                            <input type={"file"} hidden={true} ref={props.fileRefUpload} onChange={props.contentUploadFile} />
                            <div onClick={props.changeRefUpload} className={"shadow rounded border border-dashed border-gray-200 w-full bg-white p-5  h-full flex flex-col items-center justify-center cursor-pointer"}
                                 style={{
                                     backgroundImage: `url(${BgNew})`,
                                     backgroundSize: 'cover',
                                 }}>
                                <div class="relative size-[50px] shrink-0">
                                    <svg class="w-full h-full stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
                18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
                39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                        </path>
                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
                18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
                39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                        </path>
                                    </svg>
                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                        <FontAwesomeIcon icon={faImage} className={"text-gray-600"} />
                                    </div>
                                </div>
                                <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                    {t("Ajouter une image de votre équipement")}
                                </div>
                                <span class="text-sm text-gray-600">
                                        {t(' minimun 800*400px format PNG, JPG , HEIC')}
                                   </span>
                            </div>
                        </div>
                    }
                    {/*
                    <div className={"mt-5"}>
                        <Tags tags={props.tags}  tagsSelected={tagsSelected} setTagsSelected={setTagsSelected}/>
                    </div>*/}
                    <div className="mt-3">
                        <label className={ errors.summary ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('Description')}</label>
                        <textarea
                            className={"w-full rounded"}
                            type="text"
                            name="summary"
                            placeholder={t("Décrivez votre équipement")}
                            onChange={ ( event, editor ) => { setSummary(event.target.value); storage.setItem("electrical_summary",event.target.value )} }
                        >{props.topic.content}</textarea>
                        {/*<CKEditor
                            editor={ ClassicEditor }
                            config = {{
                                extraPlugins: [ MyCustomUploadAdapterPlugin ],
                                placeholder: t('Description'),

                            }}
                            type="text"
                            name="summary"
                            onChange={ ( event, editor ) => { setSummary(editor.getData())} }
                        />*/}
                        {errors.summary && errors.summary.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>

                </div>
                <div className={"bg-gray-50 rounded p-5 border  border-dashed  border-gray-100 flex flex-col gap-5 overflow-hidden"}>
                    <div className={"first-letter:uppercase text-gray-700 font-bold text-sm"}>{t("Créer la notice")}</div>
                    <div className="mt-2 bg-gray-200 dark:bg-gray-800/50 p-4 rounded-lg border border-gray-200 dark:border-gray-700">
                        <div className="flex gap-3">
                            <FontAwesomeIcon icon={faCircleInfo}  className={"text-gray-700"}/>
                            <p className="text-sm text-gray-700 dark:text-gray-300">
                                {t('Expliquer les différentes fonctionnalités une par une de votre appareil')}
                            </p>
                        </div>
                    </div>
                    <div className={""}>
                        <div className={"first-letter:uppercase text-sm font-bold text-gray-700"}>{t('Liste des Fonctionalités')}</div>
                        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                        <div className={"mt-2"}>
                            { features?.map((feature) => (
                                <div key={feature.id} className="p-4 border rounded-lg shadow-sm bg-white mt-2">
                                    <div className="flex justify-between items-center">
                                        <div className=" font-semibold text-gray-800 first-letter:uppercase text-md">{t('Fonctionalité')}</div>
                                        <button onClick={() => removeFeature(feature.id)} className="text-red-500 hover:text-red-700">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>

                                    <input
                                        type="text"
                                        placeholder={t('Ex Allumer le four')}
                                        value={feature.name}
                                        onChange={(e) => handleChangeFonction(feature.id, "name", e.target.value)}
                                        className="w-full p-2 mt-2 border rounded-md focus:ring-2 focus:ring-blue-500 text-sm"
                                    />

                                    <textarea
                                        placeholder={t('tourner le bouton de gauche vers la droite pour allumer le four')}
                                        value={feature.description}
                                        onChange={(e) => handleChangeFonction(feature.id, "description", e.target.value)}
                                        className="w-full p-2 mt-2 border rounded-md focus:ring-2 focus:ring-blue-500 text-sm"
                                    />

                                    <div className="mt-4">
                                        <div className="mt-4">
                                            <h4 className="text-md font-semibold mb-2">{t('Images')}</h4>
                                            <div className="flex space-x-4">
                                                {feature?.images?.map((image, index) => (
                                                    <label key={index} className="relative flex flex-col items-center justify-center w-32 h-32 border-2 border-dashed rounded-lg cursor-pointer hover:border-blue-500">
                                                        <input type="file" accept="image/*" className="hidden" onChange={(e) => handleImageUpload(feature.id, index, e)} />
                                                        {image ? (
                                                            <>
                                                                {image.inEditImage === 1 ?
                                                                    <img src={global.config.uriAPI+"/"+image.url} alt="upload" className="absolute inset-0 w-full h-full object-cover rounded-lg" />

                                                                    :
                                                                    <img src={URL.createObjectURL(image)} alt="upload" className="absolute inset-0 w-full h-full object-cover rounded-lg" />

                                                                }
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        removeImage(feature.id, index);
                                                                    }}
                                                                    className="absolute top-1 right-1 bg-white p-1 rounded-full shadow-md"
                                                                >
                                                                    <FontAwesomeIcon icon={faClose} className={"text-xs"} />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className="flex flex-col items-center gap-2">
                                                                <FontAwesomeIcon icon={faImage} className={"text-gray-600"}/>
                                                                <span className="text-sm text-gray-500 first-letter:uppercase">{t('Ajouter une image')}</span>
                                                            </div>
                                                        )}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}

                            <div
                                onClick={addFeature}
                                className="flex items-center justify-center w-full p-2 text-white bg-cyan-600 rounded-md hover:bg-cyan-700 text-sm mt-2"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {t('Ajouter une fonctionnalité')}
                            </div>

                            {cropData.image && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className="bg-white p-6 rounded-lg shadow-lg">
                                        <h2 className="text-lg font-semibold mb-4">Recadrer l'image</h2>
                                        <div className="relative w-[400px] h-[300px]">
                                            <Cropper
                                                image={cropData.image}
                                                crop={cropConfig.crop}
                                                zoom={cropConfig.zoom}
                                                aspect={4 / 3}
                                                onCropChange={(crop) => setCropConfig({ ...cropConfig, crop })}
                                                onZoomChange={(zoom) => setCropConfig({ ...cropConfig, zoom })}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                        <div
                                            onClick={handleCropComplete}
                                            className="mt-4 w-full p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                                        >
                                            Valider le recadrage
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </div>

            {props.topic.media.id != undefined ?
                <button type="submit" disabled={button.disabled}
                        className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                    {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                </button>
                :<div
                    className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5">
                    {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                </div> }
        </Form>
    )

}


async function getCroppedImg(imageSrc, cropAreaPixels) {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => (image.onload = resolve));

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 800;
    canvas.height = 600;

    ctx.drawImage(
        image,
        cropAreaPixels.x,
        cropAreaPixels.y,
        cropAreaPixels.width,
        cropAreaPixels.height,
        0,
        0,
        800,
        600
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        }, "image/jpeg");
    });
}