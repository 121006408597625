import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/logo4.svg';
import BgCard from '../../asset/img/bg-3.png';
import BgNew from '../../asset/img/bg-4.png';
import CadreAffiche from '../../asset/img/cadreaffiche2.png';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquarePlus,
    faLink,
    faTrash,
    faEdit,
    faSpinner,
    faCog,
    faWarning,
    faCircleExclamation,
    faCheck,
    faQrcode,
    faDownload,
    faChartLine,
    faCopy,
    faBook,
    faHome,
    faFolderBlank,
    faCircle,
    faSquare,
    faCogs,
    faRightFromBracket,
    faInfo,
    faCircleInfo,
    faLifeRing,
    faCircleQuestion,
    faClipboardCheck,
    faArrowRight,
    faCircleArrowRight,
    faList,
    faPlus,
    faBars,
    faXmark,
    faComment,
    faChartSimple,
    faSquarePollVertical,
    faImage,
    faUser,
    faEllipsisVertical,
    faEye,
    faEnvelope,
    faWifi,
    faRightToBracket,
    faArrowsLeftRight,
    faListCheck,
    faClose, faTriangleExclamation, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import Menu from './menu'
import {Button, Dropdown, Footer, Modal, Tooltip} from 'flowbite-react';
import {useForm} from "react-hook-form";
import Layout from './Layout';

export default function AdminIndex() {

    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();
    const [inputs, setInputs] =  useState({search :  ''});
    const [limit, setLimit] = useState(100);
    const [orderBy, setOrderBy] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [guideBooks, setGuideBooks] = useState([]);
    const [nbGuideBook, setNbGuideBook] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [refreshView, setRefreshView] = useState(0);
    const [inDelete, setInDelete] = useState(0);
    const [toast, setToast] = useState({message: '', icon: '', color: '' });
    const [uuidDelete, setUuidDelete] = useState('');
    const [plan, setPlan] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const onPageChange = (page) => {
        setCurrentPage(page);
        setRefreshView(1)

    }
    const [totalPage, setTotalPage] = useState(0);


    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var raw = JSON.stringify({"page": currentPage-1,"limit": limit, "orderBy": orderBy, 'name': searchName});
        var requestOptionsGet = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(global.config.uriAPI+'/app/plan/show', requestOptionsGet)
            .then((response) => response.json())
            .then((data) => {
                setPlan(data);
                if (plan.endPlan === 1) {
                    window.location.href = '/app/plan';
                }

            })
            .catch((err) => {
            });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/nb', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setNbGuideBook(data.nb);
                setTotalPage(Math.ceil(data.nb / limit));
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/app/guidebook/', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setRefreshView(0);
                setGuideBooks(data);
            })
            .catch((err) => {
            });

        //
    }, [currentPage, searchName]);

    const openModalDelete = (uuid) => {
        setUuidDelete(uuid);
        setOpenModal(true)
    }



// close sidebar if user clicks outside of the sidebar
    /* document.addEventListener("click", (event) => {
         const isButtonClick = btn === event.target && btn.contains(event.target);
         const isOutsideClick =
             sidebar !== event.target && !sidebar.contains(event.target);

         // bail out if sidebar isnt open
         if (sidebar.classList.contains("-translate-x-full")) return;

         // if the user clicks the button, then toggle the class
         if (isButtonClick) {
             console.log("does not contain");
             sidebar.classList.toggle("-translate-x-full");
             return;
         }

         // check to see if user clicks outside the sidebar
         if (!isButtonClick && isOutsideClick) {
             console.log("outside click");
             sidebar.classList.add("-translate-x-full");
             return;
         }
     });*/


    const deleteCall = () => {
        const uuid = uuidDelete;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        setInDelete(1);
        var raw = JSON.stringify({"uuid": uuid});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/guidebook/remove', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                var raw = JSON.stringify({"page": currentPage-1,"limit": limit, "orderBy": orderBy, 'name': searchName});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch(global.config.uriAPI+'/app/guidebook/', requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setRefreshView(0);
                        setGuideBooks(data);
                        setInDelete(0);
                        setOpenModal(false);
                        setToast({message: t('Le livret a bien été supprimé'), icon: faCheck, color: 'green' });
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
            });
    }

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Tableau de bord')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            {t('Bienvenue sur Okurensio  création de livret d\'accueil numérique')}
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mx-auto mt-5">
                <div class="grid lg:grid-cols-3 g gap-4 items-stretch ">
                    <div className={"lg:col-span-1 "}>
                        <div className={"grid gap-5 h-full"}>
                            <div className={"shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-col"}
                                 style={{
                                     backgroundImage: `url(${BgCard})`,
                                     backgroundSize: 'cover',
                                 }}
                            >
                                <div>
                                    <FontAwesomeIcon icon={faSquarePollVertical} size="2x" className={"text-blue-400"} />
                                </div>
                                <div className={"mt-10 text-3xl font-semibold text-gray-900"}>
                                    {guideBooks.totalYear}
                                </div>
                                <div className={"first-letter:uppercase text-gray-600 text-sm"}>{t('visites')}</div>

                            </div>
                            <div className={"shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-col"}
                                 style={{
                                     backgroundImage: `url(${BgCard})`,
                                     backgroundSize: 'cover',
                                 }}
                            >
                                <div>
                                    <FontAwesomeIcon icon={faSquarePollVertical} size="2x" className={"text-blue-400"} />
                                </div>
                                <div className={"mt-10 text-3xl font-semibold text-gray-900"}>
                                    {guideBooks.totalToday}
                                </div>
                                <div className={"first-letter:uppercase text-gray-600 text-sm"}>{t('visites aujourd\'hui')}  </div>

                            </div>
                        </div>

                    </div>
                    <div className={"lg:col-span-2 "}>
                        <div className={"shadow rounded border border-gray-200 w-full bg-white p-5 flex  h-full "}
                        >
                            <div className={"flex flex-col gap-5 p-5"}>
                                <div>
                                    <FontAwesomeIcon icon={faImage} size="4x" className={"text-green-500"} />

                                </div>

                                <h2 className={"text-2xl font-semibold text-gray-900 "}>{t('Une Affiche A4 pour mon livret')}</h2>
                                <div className={"first-letter:uppercase text-gray-600 text-sm "}>{t('Imprimer votre affiche pour votre livret au format ')} <strong>A4</strong> {t('et installez la dans votre location')}</div>
                                <div className={"first-letter:uppercase text-gray-600 text-sm "}>{t('Vos voyageurs scanne le')} <strong>QRCode</strong> {t('et accéde directement à votre livret')}</div>
                                <a href={"/app/viewer"} className={"border text-base border-cyan-700 bg-cyan-500 text-white p-2 w-full rounded-sm hover:bg-transparent hover:text-cyan-700  oswald-font flex justify-center items-center gap-2"}>
                                    {t('Générer une affiche')}</a>
                            </div>
                            <div className={"flex items-center justify-center hidden lg:block md:block"}>
                                <img src={CadreAffiche} className={"rounded"} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="container mx-auto mt-5">
                <div className={"grid lg:grid-cols-2 gap-5 items-stretch"}>
                    {guideBooks?.guideBook?.length > 0 ? (
                        <>
                            {guideBooks.guideBook.map((guideBook) =>
                                    <div className={"shadow rounded border border-gray-200 w-full bg-white p-5  h-full flex flex-col "}>
                                        <div className={"flex justify-between"}>
                                            {guideBook.mediaId === null || guideBook.ruleInterior===""  ?
                                                <Tooltip content={t('Il manque l\'image du logement')} placement={"right"}>
                                                    <div class="relative size-[50px] shrink-0">
                                                        <svg class=" stroke-1 stroke-yellow-600 fill-yellow-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                            </path>
                                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                            </path>
                                                        </svg>
                                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                            <FontAwesomeIcon icon={faListCheck} className={"text-yellow-600"} />
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                                :
                                                <div class="relative size-[50px] shrink-0">
                                                    <svg class=" stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                        </path>
                                                        <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                        </path>
                                                    </svg>
                                                    <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                        <FontAwesomeIcon icon={faCheck} className={"text-green-600"} />
                                                    </div>
                                                </div>
                                            }




                                            <Dropdown
                                                arrowIcon={false}
                                                inline
                                                label={<FontAwesomeIcon icon={faEllipsisVertical} className={"text-gray-500 bg-gray-100 rounded p-2"}/>}
                                                placement="left"

                                            >
                                                <Dropdown.Item href={"/app/guidebook/show/"+guideBook.uuid}><FontAwesomeIcon icon={faEye} className={"mr-2"}/> {t('Editer')}</Dropdown.Item>
                                                <Dropdown.Item href={"/guidebook/"+guideBook.uuid} target={"_blank"}><FontAwesomeIcon icon={faBook}  className={"mr-2"}/>{t('Apercu')}</Dropdown.Item>
                                                <Dropdown.Item href={"/app/stats/"+guideBook.uuid}><FontAwesomeIcon icon={faChartLine}  className={"mr-2"}/>{t('Statistiques')}</Dropdown.Item>
                                                {guideBooks?.plan?.nbGuidebook > 2 ?
                                                    <Dropdown.Item href={"/app/guidebook/add?guideBookToDuplicateUUID="+guideBook.uuid}><FontAwesomeIcon icon={faCopy}  className={"mr-2"}/>{t('Dupliquer')}</Dropdown.Item>
                                                    : null}
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => openModalDelete(guideBook.uuid)} data-uuid={guideBook.uuid} className={"text-red-700 bg-red-200"}><FontAwesomeIcon icon={faTrash} className={"mr-2"}/> {t('Supprimer')}</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                        <div className={"flex items-center justify-center text-2xl text-gray-700 oswald-font mt-5"}>
                                            {guideBook.name}
                                        </div>
                                        <div className={"flex items-center text-sm justify-center text-gray-600 mt-2"}>
                                            {guideBook.address}
                                        </div>
                                        <div className={"grid lg:grid-cols-2 gap-5 mt-5"}>
                                            <div className={" rounded  w-full bg-white p-5 flex flex-row gap-2 items-center "}
                                                 style={{
                                                     backgroundImage: `url(${BgCard})`,
                                                     backgroundSize: 'cover',
                                                 }}
                                            >
                                                <div>
                                                    <FontAwesomeIcon icon={faSquarePollVertical} size="2x" className={"text-gray-200"} />
                                                </div>
                                                <div className={" text-xl font-semibold text-gray-900"}>
                                                    {guideBook.stats_year}
                                                </div>
                                                <div className={"first-letter:uppercase text-gray-600 text-sm"}>{t('visites')}</div>

                                            </div>
                                            <div className={" rounded  w-full bg-white p-5 flex flex-row gap-2 items-center"}
                                                 style={{
                                                     backgroundImage: `url(${BgCard})`,
                                                     backgroundSize: 'cover',
                                                 }}
                                            >
                                                <div>
                                                    <FontAwesomeIcon icon={faSquarePollVertical} size="2x" className={"text-gray-200"} />
                                                </div>
                                                <div className={" text-xl font-semibold text-gray-900"}>
                                                    {guideBook.stats_today}
                                                </div>
                                                <div className={"first-letter:uppercase text-gray-600 text-sm"}>{t('visites aujourd\'hui')}</div>
                                            </div>
                                        </div>
                                        {guideBook.duplicate.map((duplicate) =>
                                            <div className={"flex flex-col gap-2 border border-gray-50 rounded p-2 mt-2 shadow"}>
                                                <div className={"flex flex-row gap-5 items-center justify-between "}>
                                                    <div>
                                                        <FontAwesomeIcon icon={faCopy}  className={"text-gray-600"}/> <span className={"first-letter:uppercase text-gray-700 text-md font-semibold"}>{duplicate.name}</span>
                                                    </div>


                                                    <Dropdown
                                                        arrowIcon={false}
                                                        inline
                                                        label={<FontAwesomeIcon icon={faEllipsisVertical} className={"text-gray-500 bg-gray-100 rounded p-2"}/>}
                                                        placement="left"

                                                    >
                                                        <Dropdown.Item href={"/app/guidebook/show/"+duplicate.uuid}><FontAwesomeIcon icon={faEye} className={"mr-2"}/> {t('Editer')}</Dropdown.Item>
                                                        <Dropdown.Item href={"/guidebook/"+duplicate.uuid} target={"_blank"}><FontAwesomeIcon icon={faBook}  className={"mr-2"}/>{t('Apercu')}</Dropdown.Item>
                                                        <Dropdown.Item href={"/app/stats/"+duplicate.uuid}><FontAwesomeIcon icon={faChartLine}  className={"mr-2"}/>{t('Statistiques')}</Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => openModalDelete(duplicate.uuid)} data-uuid={duplicate.uuid} className={"text-red-700 bg-red-200"}><FontAwesomeIcon icon={faTrash} className={"mr-2"}/> {t('Supprimer')}</Dropdown.Item>
                                                    </Dropdown>
                                                </div>
                                                <div className={"text-gray-600 text-sm"}>
                                                    {duplicate.address}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                )}
                        </>
                    ): null}

                    {(guideBooks?.plan?.endPlan === 0 ) ?
                        (
                            guideBooks?.plan?.isBuy === true ?
                                <a href={"/app/guidebook/add"} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                                   style={{
                                       backgroundImage: `url(${BgNew})`,
                                       backgroundSize: 'cover',
                                   }}>
                                    <div class="relative size-[50px] shrink-0">
                                        <svg class="w-full h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                            </path>
                                            <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                            </path>
                                        </svg>
                                        <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                            <FontAwesomeIcon icon={faSquarePlus} className={"text-green-600"} />
                                        </div>
                                    </div>
                                    <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                        {t('Créer un livret')}
                                    </div>
                                </a>
                                :
                                (
                                    guideBooks.nbGuidebooks < plan?.nbGuidebook ?
                                        <a href={"/app/guidebook/add"} className={"shadow rounded border border-dashed border-green-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                                           style={{
                                               backgroundImage: `url(${BgNew})`,
                                               backgroundSize: 'cover',
                                           }}>
                                            <div class="relative size-[50px] shrink-0">
                                                <svg class="w-fit h-full stroke-1 stroke-green-600 fill-green-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                    </path>
                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                    </path>
                                                </svg>
                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                    <FontAwesomeIcon icon={faSquarePlus} className={"text-green-600"} />
                                                </div>
                                            </div>
                                            <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                                {t('Créer un livret')}
                                            </div>
                                        </a>

                                        :
                                        <div className={"shadow rounded border border-dashed border-gray-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                                             style={{
                                                 backgroundImage: `url(${BgNew})`,
                                                 backgroundSize: 'cover',
                                                 opacity: 0.5
                                             }}>
                                            <div class="relative size-[50px] shrink-0">
                                                <svg class="w-full h-full stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                                    </path>
                                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                                    </path>
                                                </svg>
                                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                                    <FontAwesomeIcon icon={faSquarePlus} className={"text-gray-600"} />
                                                </div>
                                            </div>
                                            <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                                {t('Créer un livret')}
                                            </div>
                                            <span class="text-2sm text-gray-700">
                                    {t('Modifier mon abonnement')}
                               </span>

                                        </div>
                                )
                        )

                        : <div className={"shadow rounded border border-dashed border-gray-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                               style={{
                                   backgroundImage: `url(${BgNew})`,
                                   backgroundSize: 'cover',
                                   opacity: 0.5
                               }}>
                            <div class="relative size-[50px] shrink-0">
                                <svg class="w-full h-full stroke-1 stroke-gray-600 fill-gray-50" fill="none" height="48" viewBox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                                    </path>
                                    <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                                    </path>
                                </svg>
                                <div class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4">
                                    <FontAwesomeIcon icon={faSquarePlus} className={"text-gray-600"} />
                                </div>
                            </div>
                            <div className={"first-letter:uppercase text-lg font-medium text-gray-900 hover:text-primary-active mb-px mt-5"}>
                                {t('Créer un livret')}
                            </div>
                            <span class="text-2sm text-gray-700">
                                    {t('Modifier mon abonnement')}
                               </span>

                        </div>}


                </div>
            </div>
            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('Vous confirmer vouloir supprimer ce livret ? ')}
                        </h3>
                        {inDelete === 0 ?
                            <div className="flex justify-center gap-4">
                                <a href={"#"}
                                   data-uuid = {""}
                                   className={" border-2 border-red-700  text-red-700 red text-xs rounded p-1 flex items-center justify-center hover:bg-red-700 hover:text-white"} color="failure"
                                   onClick={() => deleteCall()}>
                                    {t('Oui, supprimer')}
                                </a>
                                <Button color="gray" onClick={() => setOpenModal(false)}>
                                    {t('Non')}
                                </Button>
                            </div>
                            :
                            <div>
                                <FontAwesomeIcon icon={faSpinner} spin size="2x"/> {t('Suppression en cours...')}
                            </div>
                        }

                    </div>
                </Modal.Body>
            </Modal>
        </Layout>

    );
}
