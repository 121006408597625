import { Form, useNavigate } from "react-router-dom";
import Error from "../component/Error";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../asset/img/logo4.svg';
import Loginimg from '../asset/img/voyage3.png';
import Lang from '../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";

import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {Navigate} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import GoogleButton from "./GoogleButton";
import LocalizedLink from "./LocalizedLink";
import RedirectWithLocale from "./redirectWithLocale";
import i18n from "../component/i18n";

export default function Login(props) {

    const navigate = useNavigate();
    const [inputs, setInputs] =  useState({username : props.username, password : ''});
    const { t } = useTranslation();

    const [error, setError] = useState({typeError : '', messageError: '', iconFont : ''});
    const [button, setButton] = useState({disabled : '', loader: ''});
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faArrowRightToBracket} />});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }

    const onSubmit = (data) => {

        //event.preventDefault();
        const username = data.username;
        const password = data.password;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"username": username,"password": password});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        fetch(global.config.uriAPI+"/login_check", requestOptions)
            .then((response) =>  {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then(result => {
                    const token = result.token;
                    localStorage.setItem('token', token);

                    localStorage.setItem('username', username);

                  //  mixpanel.track("login", { "email": username })
                    const name = '';
                    const value = '';
                    //setError(values => ({...values, [name]: value}))
                    setError(previousState => {
                        return { ...previousState, typeError: "success" }
                    });
                    setError(previousState => {
                        return { ...previousState, messageError: t('Authentification réussi') }
                    });


                //window.location.href = "/app/home";
                const baseLocale = i18n.language.split("-")[0]; // Extrait "en" si i18n.language vaut "en_Bg"
                navigate(`/app/home`);
            })
            .catch((error) =>  {

                setError(previousState => {
                    return { ...previousState, typeError: "warning" }
                });
                setError(previousState => {
                    return { ...previousState, messageError: t('Authentification impossible')}
                });

                setError(previousState => {
                    return { ...previousState, iconFont:  <FontAwesomeIcon icon={faCircleExclamation} />}
                });
                setButton(previousState => {
                    return { ...previousState, disabled: '' }
                });
                setIconButton(previousState => {
                    return { ...previousState, icon:  <FontAwesomeIcon icon={faArrowRightToBracket} />}
                });
            });
    }

    return (
    <section className="bg-gray-100 min-h-screen grid lg:grid-cols-2 grow">
        <div class={"flex lg:justify-center lg:items-center lg:p-10 order-2 lg:order-1 "}>
            <div class={"lg:shadow-xl lg:border-gray-200 lg:rounded p-10 lg:max-w-[400px] w-full bg-white"}>
                <img src={Logo} />
                <div className={"oswald-font text-2xl text-center mb-2"}>{t("Livret d'accueil numérique pour votre location saisonnière")}</div>
                <Error typeError={error.typeError} message={error.messageError} iconFont={error.iconFont} />
                <div className={"mt-5"}>
                    <GoogleButton />
                </div>
                <div class="flex items-center gap-2 mt-5 mb-5">
                       <span class="border-t border-gray-200 w-full">
                       </span>
                    <span class="text-sm text-gray-300 uppercase oswald-font">
                            {t('ou')}
                       </span>
                    <span class="border-t border-gray-200 w-full">
                       </span>
                </div>


                <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate className={"mt-5"}>
                    <div className="mt-5">
                        <label htmlFor="username" className={ errors.username ?  "block text-sm mb-2 text-red-700" : "block font-normal text-sm mb-2 text-gray-600 first-letter:uppercase oswald-font"}>{t('Adresse Email')}</label>
                        <input type="text" id="username"
                               aria-invalid={errors.username ? "true" : "false"}
                               defaultValue={inputs.username|| ""} onChange={handleChange}
                               {...register("username", { required: true , pattern : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g})}
                               className= { errors.username ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border border-gray-300 rounded w-full h-9 text-gray-700  text-sm font-medium px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 bg-gray-100 first-letter:uppercase lato-thin"
                               }
                               placeholder={t("Email...")}/>
                        {errors.username && errors.username.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                        {errors.username && errors.username.type === "pattern" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Mauvais format')}</div>
                        )}
                    </div>
                    <div className="mt-3">
                        <div className={"flex justify-between"}>
                            <label htmlFor="password" className={ errors.password ?  "block text-sm mb-2 text-red-700" : "block font-normal text-sm mb-2 text-gray-600 first-letter:uppercase oswald-font"}>
                                {t('Mot de passe')}
                            </label>
                            <div>
                                <LocalizedLink to="/lostpassword" className="py-1 w-full text-sm text-cyan-700 hover:underline
                                  oswald-font">{t('Mot de passe oublié')}?</LocalizedLink>
                            </div>
                        </div>
                        <input type="password" id="password"
                               defaultValue={inputs.password|| ""}
                               onChange={handleChange}
                               aria-invalid={errors.password ? "true" : "false"}
                               {...register("password", { required: true })}
                               className= { errors.password ?
                                   "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                   "border border-gray-300 rounded w-full h-9 text-gray-800 first-letter:uppercase lato-thin  text-sm font-medium  p-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 bg-gray-100"

                               }
                               placeholder={t('Password')}/>
                        {errors.password && errors.password.type === "required" && (
                            <div className={"text-red-500 p-1 mt-3 rounded text-sm"}> <FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                        )}
                    </div>
                    <div className="mt-5">
                        <button type="submit" disabled={button.disabled}
                                className="border text-base border-cyan-700 bg-cyan-500 text-white p-2 w-full rounded-sm hover:bg-transparent hover:text-cyan-700  oswald-font
                                flex justify-center items-center gap-2
                                ">
                            {iconButton.icon}<span className={"first-letter:uppercase"}>{t('Connection')}</span>
                        </button>
                    </div>
                    <div class="flex items-center gap-2 mt-5 mb-5">
                       <span class="border-t border-gray-200 w-full">
                       </span>
                                        <span class="text-sm text-gray-300 uppercase oswald-font">
                            {t('ou')}
                       </span>
                                        <span class="border-t border-gray-200 w-full">
                       </span>
                    </div>
                    <div className="mt-3 flex  flex justify-between ">
                        <div>
                            <LocalizedLink to="/register" className="text-cyan-700 py-1 w-full text-sm hover:underline
                                  oswald-font">{t('Créer un compte')}</LocalizedLink>
                        </div>
                        <div>
                            <LocalizedLink to="/" className="text-cyan-700 py-1 w-full text-sm hover:underline
                                 oswald-font">{t('Accueil')}</LocalizedLink>
                        </div>
                    </div>
                </Form>
                <Lang />
            </div>

        </div>
        <div class={"hidden md:block lg:block lg:rounded-xl lg:border lg:border-gray-200 lg:m-5 order-1 lg:order-2 bg-top xxl:bg-center xl:bg-cover bg-no-repeat branded-bg"}
            style={{backgroundImage: "url('"+Loginimg+"')"}}
        >

        </div>
        
        <CookieConsent
            location="bottom"
            buttonText={t('Accepter')}
            cookieName="okurensio"
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "#0097B2" , color: "#ffffff", fontSize: "13px" }}
            expires={150}
        >
            {t('Nous utilitons des cookies pour améliorer votre expérience d\'utilisation')}.{" "}
            <span style={{ fontSize: "10px" }}></span>
        </CookieConsent>
    </section>
    )
}

