import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faCircleDown, faPercentage, faSquarePlus
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useLocation, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label, Tooltip} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import BreadCrumb from "./BreadCrumb";
import Layout from "../Layout";
import IconSearch from "./IconSearch";
import DeleteByPath from "./function/DeleteByPath";
import BgNew from "../../../asset/img/bg-4.png";

export default function EditSubCategory(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, categoryUuid, uuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [button, setButton] = useState({disabled : '', loader: ''});
    const location = useLocation()
    const params = new URLSearchParams(location.search)
//const [searchParams] = useSearchParams();
//console.log(searchParams);
    const origin = params.get("origin");
    const [loadData, setLoadData] = useState(false);

    const { t } = useTranslation();
    const meta = {
        title: t('Modifier une sous categorie')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: ''});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});
    const [icon, setIcon] = useState('');
    const [iconSelected, setIconSelected] = useState('');
    const [topics, setTopics] = useState([]);
    const [guideBook, setGuideBook] = useState({});
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(global.config.uriAPI+'/app/subcategory/show/'+uuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setInputs({
                    name: data.name,
                    logo: data.media.url
                });
                setIcon( "<i class='fa-solid fa-"+data.icon+" fa-2x' /> ");
                setIconSelected(data.icon);
                setTopics(data.topics);
            })
            .catch((err) => {
            });

        fetch(global.config.uriAPI+'/app/guidebook/show/'+guideBookUuid, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                    setGuideBook(data)
                }
            );

    }, []);
    const onSubmit = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        /*let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;

            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }*/

        setLoadData(false);

        //formData.append('logo', fileLogo);
        formData.append('name', inputs.name);
        formData.append('subCategoryUuid', uuid);
        formData.append('icon', iconSelected);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/subcategory/update', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = document.referrer;
                return;
            })
            .catch((err) => {
            });
    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">
                            {t('Ajouter une sous carégorie')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Categories"), url: "/app/guidebook/catSubList/"+guideBookUuid+"/"+categoryUuid}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
            <div className=" mt-5 shadow rounded border border-gray-200 w-full bg-white p-5">
                {inputs.name !== '' ?
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate" className={"flex flex-col gap-5"}>

                        {guideBook.hasDuplicate === true ?
                            <><b><div className={"first-letter:uppercase"}>{inputs.name}</div> </b></>
                            :
                            <>
                                <b>{t('Modifier une  sous catégorie')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
                                <div id="fileUpload" className="w-full">
                                    <div className="mb-2  flex flex-row gap-2 justify-start items-center">
                                        <Label htmlFor="file" value={t('Choisir une icône')} /> {icon !== "" ?  <span dangerouslySetInnerHTML={{__html: icon}} className={"text-gray-600"}></span>: <></>}
                                    </div>
                                    <IconSearch  setIcon={setIcon} setIconSelected={setIconSelected}/>

                                </div>
                                <div className="mt-3 w-full">
                                    <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2"}>{t('Le nom de la catégorie')}</label>
                                    <input type="text" id="name"
                                           name ="name"
                                           aria-invalid={errors.name ? "true" : "false"}
                                           defaultValue={inputs.name|| ""}
                                           {...register("name", { onChange: handleChange, required: true})}
                                           className= { errors.name ?
                                               "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                               "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                           }
                                           placeholder={t("Nom de la catégorie")}/>
                                    {errors.name && errors.name.type === "required" && (
                                        <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                                    )}
                                </div>
                                {iconSelected != '' ?
                                    <button type="submit" disabled={button.disabled}
                                            className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </button>
                                    :<div
                                        className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5">
                                        {iconButton.icon}&nbsp;&nbsp; {t('Modifier')}
                                    </div> }
                            </>
                        }

                    </Form>
                    : <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> }

            </div>
                <div className={"mt-5"}>
                    <a
                        href={ origin === "logement" ? "/app/guidebook/addtopic/"+guideBookUuid+"/"+uuid+"?origin=logement" :  "/app/guidebook/addtopic/"+guideBookUuid+"/"+uuid}

                       className={"shadow rounded border border-dashed border-cyan-400 w-full bg-white p-5  h-full flex flex-col items-center justify-center"}
                       style={{
                           backgroundImage: `url(${BgNew})`,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                       }}>
                        <div
                            className="relative size-[50px] shrink-0">
                            <svg class="w-full h-full stroke-1 stroke-cyan-600 fill-cyan-50" fill="none"
                                 height="48" viewBox="0 0 44 48" width="44"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506
			18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937
			39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z"
                                      fill="">
                                </path>
                                <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506
			18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937
			39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z"
                                      stroke="">
                                </path>
                            </svg>
                            <div
                                class="absolute leading-none left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 cursor-pointer">
                                <FontAwesomeIcon icon={faSquarePlus} className={"text-cyan-600"}/>
                            </div>
                        </div>
                        <div
                            className={"first-letter:uppercase text-lg font-medium text-gray-600 hover:text-primary-active mb-px mt-5"}>
                            {origin === "logement" ? t('Ajouter un équipement') : t('Ajouter un contenu') }
                        </div>
                    </a>
                </div>
                <div className=" mt-5 ">
                    {topics.map((topic) =>
                        <div className=" mt-2 shadow rounded border border-gray-200 w-full bg-white p-5 flex flex-col gap-2 ">
                            <h2 className={"font-bold text-gray-700 text-lg first-letter:uppercase"}>{topic.name} </h2>
                            <span className={"text-gray-600 text-xs"}>{topic.distance/1000} Km</span>
                            <div className={"flex flex-row gap-2"}>
                                {topic.media?.url !== undefined ?<>
                                    <img src={global.config.uriAPI+"/"+topic.media.url} className={"w-10 h-10 object-cover rounded"} />
                                </> :<></> }
                                {topic.type === "electrical_appliance" ?
                                    <Tooltip content={t("Télécharger le QRCode")}>
                                        <a href={global.config.uriAPI+'/qrcode/electrical-'+topic.uuid+'.png'} target={"_blank"} className={"h-10 w-10 flex justify-center items-center"} style={{
                                            backgroundImage: `url(${global.config.uriAPI+'/qrcode/electrical-'+topic.uuid+'.png'})`,
                                            backgroundColor: 'rgba(255,255,255,0.5)',
                                            backgroundBlendMode: 'overlay',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}>
                                            <FontAwesomeIcon icon={faCircleDown}  className={"text-gray-700"}/>
                                        </a>
                                    </Tooltip>


                                    : <></> }
                            </div>

                            {topic?.mediaLibrary !== undefined ? <div className={"flex gap-2 flex-row"}>
                                {topic?.mediaLibrary.map((media) =>
                                    <>
                                        <img src={global.config.uriAPI+"/"+media.url} className={"w-10 h-10 object-cover rounded"} />
                                    </>
                                )}

                            </div>: <></>}
                            <div className={"flex justify-between items-center mt-5"}>
                                <div className={"flex flex-row justify-start items-stretch gap-2"}>
                                    <Tooltip content={t('Modifier')}>
                                        <a href={"/app/guidebook/edittopic/"+guideBookUuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-gray-200 text-gray-700"}><FontAwesomeIcon icon={faEdit} /></a>
                                    </Tooltip>
                                    <Tooltip content={t('Code de promotion')}>
                                        {topic.discountCode === true ?
                                            <a href={"/app/guidebook/discountcodetopic/"+guideBookUuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-cyan-200 text-cyan-700"}><FontAwesomeIcon icon={faPercentage} /></a>
                                            :
                                            <a href={"/app/guidebook/discountcodetopic/"+guideBookUuid+"/"+topic.uuid} className={"rounded text-sm p-2 bg-gray-200 text-gray-700"}><FontAwesomeIcon icon={faPercentage} /></a>
                                        }

                                    </Tooltip>
                                </div>
                                <DeleteByPath path={"/app/topic/remove/"+topic.uuid} setter={setTopics}  uuid={topic.uuid}></DeleteByPath>
                            </div>
                        </div>
                    )}
                </div>


        </section>
        </Layout>
    )

}