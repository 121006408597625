
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import Layout from '../Layout';

export default function OtaListing() {

    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const { t } = useTranslation();

    const [annonces, setAnnonces] = useState(null)

    useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/userota/add', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                setAnnonces(data)
            })
            ;

    }, []);

    return (
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            {t('Listing des annonces OTA')}
                        </h1>

                    </div>
                </div>
            </div>
            <div class="container mx-auto mt-5">
                <div class="w-full">
                    {annonces !== null ?
                        <>
                            {annonces.map((annonce) =>
                                <div className={"rounded bg-gray-100 p-2 flex flex-row gap-5 w-full" }>
                                    <img src={annonce.picture} className={"rounded"}/>
                                    <div>
                                        <div className={"text-gray-700 text-lg font-semibold"}>{annonce.name}</div>
                                        <div className={"text-gray-600"}>{annonce.address}</div>
                                        <div className={"bg-red-600 rounded p-1 text-white text-sm w-fit"}>{t('Airbnb')}</div>
                                    </div>

                                </div>
                            )}
                        </>
                        : <></>}
                </div>

            </div>
        </Layout>
    )

}