import Error from "../../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../../asset/img/okurensiologo.svg';
import Loginimg from '../../../asset/img/loginimg.png';
import Lang from '../../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faForward,
    faBook,
    faSpinner,
    faEdit,
    faHome,
    faCircleInfo, faLocationDot, faSearch, faAdd, faImage
} from "@fortawesome/free-solid-svg-icons";
import Menu from '../menu'
import {useForm} from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {Form, useParams} from "react-router-dom";
import i18n from "../../../component/i18n";
import  Meta  from "../../../component/Meta";
import {Breadcrumb, FileInput, Label} from "flowbite-react";
import FontPicker from "react-fontpicker-ts";
import {TwitterPicker} from "react-color";
import Layout from "../Layout";
import BreadCrumb from "./BreadCrumb";
import UploadFile from "./uploadFile";
import DeleteMedia from "./function/DeleteMedia";
import BgNew from "../../../asset/img/bg-4.png";
import IconSearch from "./IconSearch";

export default function AddSubCategory(props) {
    const MAX_FILE_SIZE = 5120 // 5MB
    let { guideBookUuid, categoryUuid } = useParams();
    const token = localStorage.getItem('token');
    if(token === null)  window.location.href = '/login';
    const [button, setButton] = useState({disabled : '', loader: ''});

    const [loadData, setLoadData] = useState(false);

    const { t } = useTranslation();
    const meta = {
        title: t('Ajouter une categorie')
    }
    const [inputs, setInputs] =  useState({ logo: '', name: ''});

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
    } = useForm();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        //setError( {...Error , ['type'] : ''});
        //setError( {...Error , ['message'] : ''});
    }
    const [iconButton, setIconButton] = useState({icon : <FontAwesomeIcon icon={faAdd} />});

    const onSubmit = (data) => {

        setButton(previousState => {
            return { ...previousState, disabled: 'disabled' }
        });
        setIconButton(previousState => {
            return { ...previousState, icon:  <FontAwesomeIcon icon={faSpinner} spin/>}
        });

        const myHeaders = new Headers();
        //myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        //myHeaders.append('Content-Type', 'Multipart/form-data');

        let formData = new FormData()

        /*let fileLogo = document.querySelector('#logo').files[0];
        if (fileLogo) {
            const fileSizeKiloBytesLogo = fileLogo.size / 1024
            const extensionLogo = fileLogo.type;
            console.log(fileLogo.width);

            if (['image/png', 'image/jpg', 'image/jpeg'].includes(extensionLogo) === false) {
                setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
                return;
            }

            if(fileSizeKiloBytesLogo > MAX_FILE_SIZE){
                setError('logo', { type: 'size', message: t('Le fichier est trop volumineux') });
                return
            }
        }*/

        setLoadData(false);


        formData.append('name', inputs.name);
        formData.append('icon', iconSelected);
        formData.append('categoryUuid', categoryUuid);
        formData.append('guideBookUuid', guideBookUuid);
        //formData.append("mediaId", subCategory.media.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI + '/app/subcategory/create', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                window.location.href = document.referrer;
                return;
            })
            .catch((err) => {
            });
    }


    const validLogo = () => {
        let fileData = document.querySelector('#logo').files[0];
        const extension = fileData.type;

        if (['image/png', 'image/jpg', 'image/jpeg'].includes(extension) === false) {
            setError('logo', { type: 'error', message: t('Le fichier doit être au format png, jpg ou jpeg') });
            return
        }
        setError('logo', null);
    }

    const changeRefUpload = () => {
        fileRefUpload.current.click();
    }
    const fileRefUpload = React.createRef()
    const [upload, setUpLoad] = useState(0);
    const [eventUpload, setEventUpload] = useState(null);
    const[reload, setReload] = useState(1);
    const  contentUploadFile = (event) => {
        setUpLoad(1);
        setEventUpload(event);
    }

    const [subCategory, setSubCategory] = useState({media : {}});
    const [icon, setIcon] = useState('');
    const [iconSelected, setIconSelected] = useState('');

    return (
        upload === 1 ?
            <UploadFile event={eventUpload}  setUpload = {setUpLoad}  setReload={setReload}  setGuideBook={setSubCategory} guideBook={subCategory}  title={t('Image de la sous categorie')}  url={"uploadContentImg"}/>
            :
        <Layout>
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900 first-letter:uppercase">
                            {t('Ajouter une sous carégorie')}
                        </h1>
                        <div class="flex items-center gap-2 text-sm font-normal text-gray-700">
                            <BreadCrumb links={[{name: t("Categories"), url: "/app/guidebook/catSubList/"+guideBookUuid+"/"+categoryUuid}]} />
                        </div>
                    </div>
                </div>
            </div>

            <section className={"container mx-auto"}>
                <Meta meta={meta} />
                <div className="mt-5 shadow rounded border border-gray-200 w-full bg-white p-5 ">
                    <Form action="" method="post" onSubmit={handleSubmit(onSubmit)} noValidate id="formCreate" className={"flex flex-col gap-5"}>
                        <b>{t('Ajouter une sous catégorie')} : <span className={"first-letter:uppercase"}>{inputs.name}</span> </b>
                        <div id="fileUpload" className="w-full">
                            <div className="mb-2  flex flex-row gap-2 justify-start items-center">
                                <Label htmlFor="file" value={t('Choisir une icône')} /> {icon !== "" ?  <span dangerouslySetInnerHTML={{__html: icon}} className={"text-gray-600"}></span>: <></>}
                                <p className={"text-gray-600 text-sm "}>{t('Taper le nom en anglais')}</p>
                            </div>
                            <IconSearch  setIcon={setIcon} setIconSelected={setIconSelected}/>
                        </div>
                        <div className="mt-3 w-full">
                            <label htmlFor="name" className={ errors.name ?  " text-sm block mb-2 text-red-700" : "block text-sm mb-2 text-gray-700 font-semibold"}>{t('Le nom de la catégorie')}</label>
                            <input type="text" id="name"
                                   name ="name"
                                   aria-invalid={errors.name ? "true" : "false"}
                                   defaultValue={inputs.name|| ""}
                                   {...register("name", { onChange: handleChange, required: true})}
                                   className= { errors.name ?
                                       "border border-red-700 rounded w-full text-base text-red-700 px-2 py-1 focus:outline-none focus:ring-0 focus:border-red-600" :
                                       "border rounded w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600 w-full"
                                   }
                                   placeholder={t("Nom de la catégorie")}/>
                            {errors.name && errors.name.type === "required" && (
                                <div className={"text-red-500 p-1 mt-3 rounded text-sm"}><FontAwesomeIcon icon={faCircleExclamation} /> {t('Champs obligatoire')}</div>
                            )}
                        </div>
                        {iconSelected != '' ?
                            <button type="submit" disabled={button.disabled}
                                    className="border-2 border-cyan-700 bg-cyan-500 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-cyan-700 font-semibold">
                                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                            </button>
                            :<div
                                className="border-2 border-gray-700 bg-gray-500 text-white py-1 w-full rounded-md flex items-center justify-center p-5">
                                {iconButton.icon}&nbsp;&nbsp; {t('Enregistrer')}
                            </div> }
                    </Form>
                </div>
            </section>

        </Layout>

    )

}