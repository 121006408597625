import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {usePostHog} from "posthog-js/react";

const GoogleConnectButton = () => {

    const langId = localStorage.getItem('langId');

    const posthog = usePostHog()
    const handleSuccess = async (credentialResponse) => {
        const idToken = credentialResponse.credential;
        try {
            const response = await fetch(global.config.uriAPI+'/logingoogle', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id_token: idToken, langId: langId }),
            });
            const data = await response.json();
            localStorage.setItem('token', data.token);
            localStorage.setItem('username', data.email);
            posthog.capture(
                'signup',
                {
                    $set: { referer: document.referrer }
                }
            )
            window.location.href = "/app/home";

            // Stockez le token et redirigez l'utilisateur, par exemple.
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
        }
    };

    const handleError = () => {
        console.error('Connexion Google échouée');
    };

    return (
        <GoogleOAuthProvider clientId="1014053882450-nclu24tooftojsm9mt2pa89jsulniej2.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={handleSuccess}
                onError={handleError}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleConnectButton;
