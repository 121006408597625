import Error from "../../component/Error";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Logo from '../../asset/img/okurensiologo.svg';
import Loginimg from '../../asset/img/loginimg.png';
import Lang from '../../component/lang.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faForward,
    faUser,
    faSpinner,
    faBars,
    faXmark,
    faHome,
    faSquarePlus,
    faList, faCircleArrowRight, faCircleQuestion, faClipboardCheck, faComment, faCogs, faRightFromBracket, faPlus
} from "@fortawesome/free-solid-svg-icons";

import {
    Avatar,
    Dropdown,
    Navbar,
} from 'flowbite-react';
import mixpanel from "mixpanel-browser/src/loader-module";
import i18n from "../../component/i18n";
import airbnb from '../../asset/img/airbnb.svg';

export default function Menu() {

    const { t } = useTranslation();
    mixpanel.init(global.config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});


    ///app/user/profile
    const token = localStorage.getItem('token');
    const [user, setUser] = useState([]);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/user/profile', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                setUser(data);
                localStorage.setItem('langId', data.langId);
                localStorage.setItem('langStr', data.lang);
                i18n.changeLanguage(data.lang);
            })
            .catch((err) => {
                localStorage.setItem('token', null);
               window.location.href = '/login';
            });

        //
    }, []);

    const logout = () => {
        localStorage.setItem('token', null);
        window.location.href = '/login';
    }


    const openMenu = (event) => {
        event.stopPropagation();
        const btn = document.querySelector(".mobile-menu-button");
        const sidebar = document.getElementById("sidebar");
        const content = document.getElementById("content");

// add our event listener for the click
        sidebar.classList.toggle("-translate-x-full");

        document.addEventListener("click", (event) => {
            const isButtonClick = btn === event.target && btn.contains(event.target);
            const isOutsideClick =
                sidebar !== event.target && !sidebar.contains(event.target);

            // bail out if sidebar isnt open
            if (sidebar.classList.contains("-translate-x-full")) return;

            // if the user clicks the button, then toggle the class
            if (isButtonClick) {
                sidebar.classList.toggle("-translate-x-full");
                return;
            }
            // check to see if user clicks outside the sidebar
            if (!isButtonClick && isOutsideClick) {
                sidebar.classList.add("-translate-x-full");
                return;
            }
        });
    }

    const closeMenu = () => {
        const btn = document.querySelector(".mobile-menu-button");
        const sidebar = document.getElementById("sidebar");
        let isSidebarOpen = false;
// add our event listener for the click
        sidebar.classList.toggle("-translate-x-full");
        document.removeEventListener("click", (event) => {});

    }

    const linkHospitable = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '+token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.config.uriAPI+'/app/userota/linkauth', requestOptions)
            .then((response) => {
                if(response.status != 200) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                window.location.href = data.return_url;
            })
            .catch((err) => {
            });
    }
    return (
        <>
        <div class="bg-cyan-700 text-gray-100 flex justify-between md:hidden z-0">
            <a href="#" className="block p-4 text-white font-bold">Okurensio</a>
            <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700" onClick={openMenu}>
                <FontAwesomeIcon icon={faBars} />
            </button>
        </div>
        <div id={"sidebar"} className={"z-40 bg-white absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out" +
            "border-gray-200 border-r-2 min-h-screen w-64 space-y-6 py-7 px-2 overflow-y-auto"} >
            <div class="flex flex-col items-start justify-start p-5 overflow-y ">
                <div className={"flex items-center justify-end text-gray-400 text-right w-full lg:hidden"}>
                    <FontAwesomeIcon icon={faXmark} id={"closeMenu"} className={""} onClick={closeMenu} />
                </div>
                <div className={"flex items-center justify-center"}>
                    <img src={Logo} className={"w-1/2"}/>
                </div>

                <a href={"/app/home"} className={" flex gap-2 items-center justify-center hover:underline mt-10"}>
                    <FontAwesomeIcon icon={faHome}  className={"text-gray-400 text-sm"}/>
                    <span  className={"first-letter:uppercase oswald-font text-gray-600 font-medium text-sm"}>{t('Accueil')}</span>
                </a>
                { user?.plan?.endPlan === 0  ?
                    (
                        user?.plan.isBuy === true ? <>
                                <a href={"/app/guidebook/add"} className={" flex gap-2 items-center justify-center border border-dashed border-green-400   mt-5  rounded p-1 w-full text-gray-600 text-sm oswald-font font-medium"}>
                                    <FontAwesomeIcon icon={faPlus} className={"text-green-600"} />
                                    <span  className={"first-letter:uppercase "}>{t('Créer un livret')}</span>
                                </a>
                        </>:
                            (user?.nbGuidebooks < user?.plan?.nbGuidebook ?
                                    <a href={"/app/guidebook/add"} className={" flex gap-2 items-center justify-center border border-dashed border-green-400   mt-5  rounded p-1 w-full text-gray-600 text-sm oswald-font font-medium"}>
                                        <FontAwesomeIcon icon={faPlus} className={"text-green-600"} />
                                        <span  className={"first-letter:uppercase "}>{t('Créer un livret')}</span>
                                    </a>
                                    :
                                    <div  className={" flex gap-2 items-center justify-center border border-dashed border-gray-400  bg-gray-50  mt-5  rounded p-1 w-full text-gray-600 text-sm oswald-font font-medium hover:cursor-not-allowed"}>
                                        <FontAwesomeIcon icon={faPlus} className={"text-gray-600"} />
                                        <span  className={"first-letter:uppercase "}>{t('Créer un livret')}</span>
                                    </div>
                            )
                    )
                    :  <div  className={" flex gap-2 items-center justify-center border border-dashed border-gray-400  bg-gray-50  mt-5  rounded p-1 w-full text-gray-600 text-sm oswald-font font-medium hover:cursor-not-allowed"}>
                        <FontAwesomeIcon icon={faPlus} className={"text-gray-600"} />
                        <span  className={"first-letter:uppercase "}>{t('Créer un livret')}</span>
                    </div>}

                <a href={""} className={" flex gap-2 items-center justify-center hover:underline mt-5"}>
                    <FontAwesomeIcon icon={faList}  className={"text-gray-400 text-sm"}/>
                    <span  className={"first-letter:uppercase oswald-font text-gray-600 font-medium text-sm"}>{t('Livrets')}</span>
                </a>

                {user?.plan?.endPlan === 0 ?
                    <a href={"/app/plan"} className={"mt-10 bg-cyan-600 p-3 text-justify text-white rounded lato-thin  text-sm font-medium   flex flex-col gap-2 w-full"}>
                        <span>{t("Abonnement en cours jusqu'au")} {user.plan.dateEnd}</span>
                        <div className={"text-white text-sm text-right"}>
                            <FontAwesomeIcon icon={faCircleArrowRight}></FontAwesomeIcon>
                        </div>
                    </a>
                    :
                    <a href={"/app/plan"} className={"mt-5 bg-yellow-500 p-3 text-justify text-white rounded lato-thin  text-sm font-medium  w-fit flex flex-col gap-2 w-full"}>
                        <span>{t("Aucun abonnement en cours")} </span>
                        <div className={"text-white text-sm text-right"}>
                            <FontAwesomeIcon icon={faCircleArrowRight}></FontAwesomeIcon>
                        </div>
                    </a>
                }

                <div className={"bg-gray-100  rounded p-2 w-full mt-5"}>
                    <a href={"https://discord.gg/AkgY5gyU"} className={" p-2 text-sm  bg-gray-100  rounded text-gray-700 text-center  w-full flex gap-2 justify-start items-center font-medium"}>
                        <FontAwesomeIcon icon={faComment} />{t('Channel Discord')}
                    </a>
                </div>



                <a href={"/app/account"} className={" flex  gap-2 items-center justify-start  mt-5 hover:underline"}>
                    <FontAwesomeIcon icon={faCogs}  className={"text-gray-400 text-sm"}/>
                    <span  className={"first-letter:uppercase oswald-font text-gray-600 font-medium text-sm"}>{t('Mon compte')}</span>


                </a>
                <span className={"text-gray-600 text-xs mt-1"}>{user.email}</span>
                <a href={""} onClick={logout} className={" flex gap-2 items-center justify-center   mt-10 bg-gray-100 rounded p-1 w-full text-gray-600  text-sm oswald-font font-medium mb-10"}>
                    <FontAwesomeIcon icon={faRightFromBracket}  className={""}/>
                    <span  className={"first-letter:uppercase "}>{t('Déconnexion')}</span>
                </a>

            </div>
        </div>
        </>
    );
}

/*
<Navbar fluid >
    <Navbar.Brand href="/app/home">
        <img src={Logo} className="mr-3 h-6 sm:h-9" alt={t('okurensio')} />
    </Navbar.Brand>
    <div className="flex md:order-2">
        {
            user.email != undefined ?
                (
                    <Dropdown
                        arrowIcon={false}
                        inline
                        label={<FontAwesomeIcon icon={faUser} />}

                    >
                        <Dropdown.Header>
                            <span className="block truncate text-sm font-medium">{user.email}</span>
                        </Dropdown.Header>
                        <Dropdown.Item href={"/app/password"}>{t('Mon compte')}</Dropdown.Item>
                        <Dropdown.Item href={"/app/plan"}>{t('Abonnement')}</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={logout}>{t('Déconnexion')}</Dropdown.Item>
                    </Dropdown>
                ):
                (
                    <FontAwesomeIcon icon={faSpinner} />
                )
        }

        <Navbar.Toggle />
    </div>
    <Navbar.Collapse>
        <Navbar.Link href="/app/home" active>
            {t('Accueil')}
        </Navbar.Link>

        <Navbar.Link href="/app/help" active>
            {t('Aide')}
        </Navbar.Link>
    </Navbar.Collapse>
</Navbar>*/