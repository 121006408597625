import {useLocation} from "react-router-dom";
import i18n from "../component/i18n";


export default function DetectLang() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
//const [searchParams] = useSearchParams();
//console.log(searchParams);
    const lang = params.get("lang");

    if (lang != undefined) {
        if (localStorage.getItem('langId') === null) {
            i18n.changeLanguage(lang);
        }
    }

}