import * as React from "react";
//import * as ReactDOM from "react-dom/client";
import ReactDom from "react-dom";
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useParams,
    Navigate
} from "react-router-dom";
import { hydrateRoot } from 'react-dom/client';
import "./asset/index.css";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Login from "./routes/login"
import Register from "./routes/register"
import AdminIndex from './routes/admin/index'
import LCDTemplate from "./routes/admin/guideBook/lcdtemplate";
import  './config';
import i18n from "./component/i18n";

import { library } from '@fortawesome/fontawesome-svg-core'
import AddGuideBook from "./routes/admin/guideBook/add";
import ShowGuideBook from "./routes/admin/guideBook/show";
import TopicListing from "./routes/admin/guideBook/topicListing";
import SettingGuidebook from "./routes/admin/guideBook/setting";
import QrCode from "./routes/admin/guideBook/qrCode";
import EditGuideBook from "./routes/admin/guideBook/edit";
import AddCategory from "./routes/admin/guideBook/addCategory";
import EditCategory from "./routes/admin/guideBook/editCategory";
import AddSubCategory from "./routes/admin/guideBook/addSubCategory";
import EditSubCategory from "./routes/admin/guideBook/editSubCategory";
import AddTopic from "./routes/admin/guideBook/addTopic";
import EditTopic from "./routes/admin/guideBook/topicEdit";
import Password from "./routes/admin/password";
import GuideBookIndex from "./routes/front/guideBook";
import Guide from "./routes/front/guide";
import SubCategory from "./routes/front/subCategory";
import Topic from "./routes/front/topic";
import DiscountCodeFront from "./routes/front/discountcode";
import Carte from "./routes/front/carte";
import TopicDetail from "./routes/front/topicdetail";
import FrontPassword from "./routes/password";
import NewPassword from "./routes/newpassword";
import Aide from "./routes/admin/aide";
import Articles from "./routes/articles";
import Article from "./routes/article";
import Cgv from "./routes/cgv";
import Abonnement from "./routes/admin/guideBook/abonnement";
import {HelmetProvider} from 'react-helmet-async';
import Tarif from "./routes/tarif";

import Stats from "./routes/admin/guideBook/stats";
import DiscountCode from './routes/admin/guideBook/discountcode';
import { PostHogProvider} from 'posthog-js/react'
import CatList from "./routes/admin/guideBook/catList";
import CatSubList from "./routes/admin/guideBook/catSubList";
import TagsCode from "./routes/front/tagsCode";
import AddEvent from "./routes/admin/guideBook/addEvent";
import UpdateEvent from "./routes/admin/guideBook/updateEvent";
import Events from "./routes/front/events";
import Viewer from "./routes/admin/guideBook/viewer";
import Account from "./routes/admin/account";
import Meteo from "./routes/front/meteo";
import OtaListing from "./routes/admin/guideBook/otaListing";

function LocaleWrapper() {
    const { locale } = useParams();
    const supportedLocales = ["fr", "en", "de", "it", "es"];

    // Récupérer la langue du navigateur et en extraire la partie de base (ex: "en" depuis "en-US")
    const browserLocale = navigator.language.split('-')[0] || "fr";

    // Déterminer la locale effective à utiliser : si l'URL fournit une locale supportée, on la garde,
    // sinon on utilise celle du navigateur si supportée, ou "fr" par défaut.
    const effectiveLocale = supportedLocales.includes(locale)
        ? locale
        : supportedLocales.includes(browserLocale)
            ? browserLocale
            : "fr";

    React.useEffect(() => {
        i18n.changeLanguage(effectiveLocale);
    }, [effectiveLocale]);

    // Si la locale dans l'URL n'est pas supportée, rediriger vers l'URL avec la locale effective
    if (!supportedLocales.includes(locale)) {
        return <Navigate to={`/${effectiveLocale}`} />;
    }

    return <Outlet />;
}

const router = createBrowserRouter([
    {
        // Redirection de la racine vers une locale par défaut
        path: "/",
        element: <Navigate to="/fr" />,
    },
    {
        path: "/:locale",
        element: <LocaleWrapper/>,
        errorElement: <ErrorPage/>,
        children: [
            // Route par défaut : index route
            {index: true, element: <Root/>},
            {path: "tarif", element: <Tarif/>, errorElement: <ErrorPage/>},
            {path: "articles", element: <Articles/>},
            {path: "cgv", element: <Cgv/>},
            {path: "article/:id/:slug", element: <Article/>},
            { path: "guidedetails/:uuid", element: <Guide /> },
            { path: "login", element: <Login /> },
            { path: "lostpassword", element: <FrontPassword /> },
            { path: "newpassword/:token", element: <NewPassword /> },
            { path: "register", element: <Register /> }
        ]
    },
    {
        path: "/guidebook",
        errorElement: <ErrorPage/>,
        children: [
            {path: ":uuid", element: <GuideBookIndex/>},
            {path: "carte/:uuid", element: <Carte/>},
            {path: "discountcode/:uuid", element: <DiscountCodeFront/>},
            {path: "tagscode/:uuid", element: <TagsCode/>},
            {
                path: "subcategory/:categoryUuid/:uuid",
                element: <SubCategory/>,
            },
            {
                path: "topic/:categoryUuid/:subCategoryUuid/:uuid",
                element: <Topic/>,
            },
            {
                path: "topicdetail/:topicUuid/:uuid",
                element: <TopicDetail/>,
            },
            {path: "meteo/:uuid", element: <Meteo/>},
            { path: "events/:guideBookUuid", element: <Events /> },
        ]
    },
    {
        path: "/app",
        errorElement: <ErrorPage/>,
        children: [
            { path: "password", element: <Password /> },

            { path: "account", element: <Password /> },
            { path: "viewer", element: <Viewer /> },
            { path: "help", element: <Aide /> },
            { path: "home", element: <AdminIndex /> },
            { path: "listingota", element: <OtaListing /> },
            { path: "guidebook/add", element: <AddGuideBook /> },
            { path: "guidebook/template/:uuid", element: <LCDTemplate /> },
            { path: "guidebook/show/:uuid", element: <ShowGuideBook /> },
            {
                path: "guidebook/addevent/:type/:nodeUuid/:guideBookUuid",
                element: <AddEvent />,
            },
            {
                path: "guidebook/updateEvent/:eventUuid/:guideBookUuid",
                element: <UpdateEvent />,
            },

            {
                path: "guidebook/subcategory/:uuid",
                element: <TopicListing />,
            },
            {
                path: "guidebook/settings/:uuid",
                element: <SettingGuidebook />,
            },
            { path: "guidebook/qrcode/:uuid", element: <QrCode /> },
            { path: "guidebook/edit/:uuid", element: <EditGuideBook /> },
            { path: "guidebook/catList/:uuid", element: <CatList /> },
            {
                path: "guidebook/catSubList/:guideBookUuid/:uuid",
                element: <CatSubList />,
            },
            { path: "guidebook/addcategory/:uuid", element: <AddCategory /> },
            {
                path: "guidebook/editcategory/:guideBookUuid/:uuid",
                element: <EditCategory />,
            },
            {
                path: "guidebook/addsubcategory/:guideBookUuid/:categoryUuid",
                element: <AddSubCategory />,
            },
            {
                path: "guidebook/editsubcategory/:guideBookUuid/:categoryUuid/:uuid",
                element: <EditSubCategory />,
            },
            {
                path: "guidebook/addtopic/:guideBookUuid/:subCategoryUuid",
                element: <AddTopic />,
            },
            {
                path: "guidebook/edittopic/:guideBookUuid/:uuid",
                element: <EditTopic />,
            },
            {
                path: "guidebook/discountcodetopic/:guideBookUuid/:uuid",
                element: <DiscountCode />,
            },
            { path: "plan", element: <Abonnement /> },
            { path: "stats/:uuid", element: <Stats /> },
        ],
    },
]);

const helmetContext = {};

const container = document.getElementById("root");
const root = createRoot(container);

const options = {
    api_host: global.config.REACT_APP_PUBLIC_POSTHOG_HOST,
};

root.render(
    <HelmetProvider context={helmetContext}>
        <PostHogProvider
            apiKey={global.config.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
        >
            <RouterProvider router={router} />
        </PostHogProvider>
    </HelmetProvider>
);

/*ReactDOM.createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
    <HelmetProvider>
        <RouterProvider router={router} />
    </HelmetProvider>
    //</React.StrictMode>
);*/
